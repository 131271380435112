
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[userSlug]/[[...tab]]",
      function () {
        return require("private-next-pages/[userSlug]/[[...tab]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[userSlug]/[[...tab]]"])
      });
    }
  