import { gql } from "@apollo/client";
import GroupFields from "~/lib/queries/fragments/GroupFields";

export default gql`
  mutation SetPrimaryGroup($groupId: ObjectId!) {
    setPrimaryGroup(id: $groupId) {
      ... on PendingUser {
        _id
        primaryGroup {
          _id
        }
        groups {
          _id
          ...GroupFields
        }
      }
      ... on GovernmentUser {
        _id
        primaryGroup {
          _id
        }
        groups {
          _id
          ...GroupFields
        }
      }
      ... on VendorUser {
        _id
        primaryGroup {
          _id
        }
        groups {
          _id
          ...GroupFields
        }
      }
    }
  }

  ${GroupFields}
`;
