interface IUser {
  savedProductsCount?: number | null;
  savedProjectsCount?: number | null;
  savedPostsCount?: number | null;
  savedStoriesCount?: number | null;
}

export default function countUserBookmarks(user: IUser) {
  return (
    (user.savedProductsCount || 0) +
    (user.savedProjectsCount || 0) +
    (user.savedPostsCount || 0) +
    (user.savedStoriesCount || 0)
  );
}
