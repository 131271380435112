import Head from "next/head";
import React from "react";
import { IUser } from "~/types/types";
import SeoHead from "~/components/seo/SeoHead";
import { useIntl } from "react-intl";
import { Tab } from "~/components/seo/common";

interface IUserPageSeoProps {
  user: Pick<IUser, "firstName" | "fullName" | "jobTitle" | "avatar">;
  tab: Tab | string | null;
}

export default function UserPageSeo({ user, tab }: IUserPageSeoProps) {
  if (!user) {
    return null;
  }

  let formatTab = tab;
  if (tab === "connect") {
    formatTab = "profile";
  }

  return (
    <>
      <SeoHead
        title={getUserFormattedTitle(formatTab, user)}
        description={user.jobTitle || getUserFormattedTitle(formatTab, user)}
      />
      <Head>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Govlaunch" />
        <meta name="twitter:image" content={user.avatar || ""} />

        <meta property="og:image" content={user.avatar || ""} />
        <meta property="og:title" content={user.fullName || ""} />
      </Head>
    </>
  );
}

export function getUserFormattedTitle(
  tab: Tab | string | null,
  user: Pick<IUser, "firstName" | "fullName" | "jobTitle" | "avatar">,
) {
  const intl = useIntl();

  if (!user) {
    return intl.formatMessage({
      defaultMessage: 'Search the wiki for local government innovation.', id: 'Pa6P7h',
    });
  }

  switch (tab) {
    case "projects":
      return intl.formatMessage(
        { defaultMessage: '{userName} – Govlaunch Projects', id: 'HZpuLW' },
        { userName: user.fullName || user.firstName },
      );
    case "saved":
      return intl.formatMessage(
        { defaultMessage: '{userName} – Govlaunch Saved', id: 'Orhat7' },
        { userName: user.fullName || user.firstName },
      );
    case "settings":
      return intl.formatMessage(
        { defaultMessage: '{userName} – Govlaunch Settings', id: 'OJjoU2' },
        { userName: user.fullName || user.firstName },
      );
    case "sharing":
      return intl.formatMessage(
        { defaultMessage: '{userName} – Govlaunch Sharing', id: '/q/nP9' },
        { userName: user.fullName || user.firstName },
      );

    default:
      return intl.formatMessage(
        { defaultMessage: '{userName} – Govlaunch Profile', id: 'teZTOp' },
        { userName: user.fullName || user.firstName },
      );
  }
}
