import { gql } from "@apollo/client";

export default gql`
  query GetDepartmentRelatedTags($departmentId: ObjectId!) {
    getDepartmentRelatedTags(departmentId: $departmentId) {
      _id
      name
    }
  }
`;
