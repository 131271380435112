import React, { ReactElement, MouseEventHandler } from "react";
import * as palette from "@govlaunch/palette";
import BookmarkIcon from "~/components/icons/BookmarkIcon";

interface IBookmarkButton {
  onClick: MouseEventHandler;
  bookmarkedColor?: string;
  bookmarked: boolean;
  bookmarkedStrokeColor?: string;
  disabled?: boolean;
}

const BookmarkButton: React.FunctionComponent<IBookmarkButton> = ({
  onClick,
  bookmarked,
  bookmarkedColor,
  bookmarkedStrokeColor,
  disabled,
  ...props
}): ReactElement => {
  return (
    <button
      type="button"
      css={{
        border: 0,
        background: "none",
        padding: 4,
        display: "inline-flex",
        cursor: disabled ? "normal" : "pointer",
        outline: "none",
        color: "#fff",
        alignSelf: "center",
      }}
      onClick={onClick}
      {...props}
    >
      <BookmarkIcon
        fill={bookmarked ? bookmarkedColor || palette.sealBlue : undefined}
        stroke={bookmarked ? bookmarkedStrokeColor : undefined}
      />
    </button>
  );
};

export default BookmarkButton;
