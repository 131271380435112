import { gql } from "@apollo/client";

export default gql`
  mutation SaveUserSharing($userSlug: String!, $sharing: UserSharingInput, $enableSharing: Boolean!) {
    saveUserSharing(userSlug: $userSlug, enableSharing: $enableSharing, sharing: $sharing) {
      ... on GovernmentUser {
        _id
        enabledSharing
        sharing {
          projects
          stories
          resources
        }
      }
    }
  }
`;
