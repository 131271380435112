import { gql } from "@apollo/client";

export default gql`
  mutation SetUserResources($resources: [ResourceInput!]!) {
    setUserResources(resources: $resources) {
      ... on GovernmentUser {
        _id
        resources {
          ... on LinkResource {
            type
            title
            url
            addedAt
          }
          ... on DownloadResource {
            type
            filename
            title
            url
            addedAt
          }
          ... on TextResource {
            type
            title
            body
            addedAt
          }
        }
      }
    }
  }
`;
