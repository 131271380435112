import { IProjectCardFieldsFragment, ProjectCard } from "@govlaunch/project";
import { PageGridContainer } from "@govlaunch/web";
import { SimpleGridProps } from "@govlaunch/web/src/grid/SimpleGrid";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import EmptyProjectSlot from "~/components/project/EmptyProjectSlot";
import { appendNextLinkHrefParams } from "~/src/utils/nextLinkHref";
import stringOrFirst from "~/src/utils/stringOrFirst";
import CreateProjectModal from "~/src/components/project/CreateProjectModal";
import ProjectModal from "~/src/components/project/modal/ProjectModal";
import computeNavigationProps from "~/src/components/project/listing/computeNavigationProps";
import noop from "lodash/fp/noop";
import useIsMobile from "~/lib/hooks/useIsMobile";
import { useIntlLocale } from "~/lib/withIntl";
import TranslationButton from "~/src/components/translation/TranslationButton";

interface IProjectCardsProps {
  projects: IProjectCardFieldsFragment[];
  gridProps?: SimpleGridProps;
  withAddProjectSlot?: boolean;
  groupId?: string;
  onClick?: (e: any, project: IProjectCardFieldsFragment, index: number) => void;
  firstLocationId?: string;
  firstLocationCountry?: string;
  onModalClose: () => void;
  projectNavigationLimit?: number;
  addProjectSlotText?: string;
  routeParams?: { [key: string]: string };
}

export default function ProjectCards({
  projects,
  gridProps,
  onClick = noop,
  withAddProjectSlot = false,
  addProjectSlotText,
  firstLocationId,
  firstLocationCountry,
  groupId,
  projectNavigationLimit,
  onModalClose,
  routeParams = {},
}: IProjectCardsProps) {
  const router = useRouter();
  const isMobile = useIsMobile();
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [relatedProject, setRelatedProject] = useState<any>(null);
  const { locale } = useIntlLocale();
  const projectSlugFromRouter = router.query.projectSlug;
  const langFromRouter = router.query.lang;
  const slugExistsOnProjects = projects.some((project) => project.slug === projectSlugFromRouter) || relatedProject;

  return (
    <>
      <CreateProjectModal
        isOpen={showCreateProjectModal}
        onClose={() => setShowCreateProjectModal(false)}
        groupId={groupId}
      />

      <PageGridContainer px={0} {...gridProps}>
        {withAddProjectSlot && (
          <EmptyProjectSlot
            onClick={() => setShowCreateProjectModal(true)}
            groupId={groupId}
            text={addProjectSlotText}
          />
        )}

        {projects.map((project, index) => {
          const linkHref = isMobile
            ? `/projects/${project.slug}`
            : appendNextLinkHrefParams(router, {
                projectSlug: project.slug,
                ...routeParams,
              });

          const translationLinkHref = isMobile
            ? `/projects/[projectSlug]`
            : appendNextLinkHrefParams(router, {
                projectSlug: project.slug,
                lang: locale,
                ...routeParams,
              });

          const translationLinkWrap = () => {
            return (
              <TranslationButton
                paddingY="4px"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  router.push(translationLinkHref, `/projects/${project.slug}?lang=${locale}`);
                }}
              />
            );
          };

          return (
            <Link href={linkHref} as={`/projects/${project.slug}`} passHref={true} key={project._id} scroll={false}>
              <ProjectCard
                data-intercom-target={index === 0 ? "First project" : undefined}
                project={project}
                onClick={(event: any) => onClick(event, project, index)}
                firstLocationId={firstLocationId}
                firstLocationCountry={firstLocationCountry}
                translationLinkWrap={locale !== "en-US" ? translationLinkWrap : undefined}
              />
            </Link>
          );
        })}
      </PageGridContainer>

      {projectSlugFromRouter && slugExistsOnProjects && (
        <ProjectModal
          onClickProject={(project) => {
            setRelatedProject(project);
          }}
          projectSlug={stringOrFirst(projectSlugFromRouter)}
          navigation={computeNavigationProps(
            projects.slice(0, projectNavigationLimit),
            stringOrFirst(projectSlugFromRouter),
          )}
          onClose={onModalClose}
          lang={stringOrFirst(langFromRouter)}
        />
      )}
    </>
  );
}
