import React, { ReactElement } from "react";
import { Margin } from "~/components/spacings";
import { IUser } from "~/types/types";
import MyDetailsSection from "~/components/user/settings/MyDetailsSection";
import MyGroupsSection from "~/components/user/settings/MyGroupsSection";
import DeleteMyAccountSection from "~/components/user/settings/DeleteMyAccountSection";
import UserEmailSubscriptionSettings from "~/components/user/settings/UserEmailSubscriptionSettings";

interface IUserProfileSettingsProps {
  user: IUser;
}

export default function UserProfileSettings({ user }: IUserProfileSettingsProps): ReactElement {
  return (
    <>
      <Margin mt={20}>
        <MyDetailsSection user={user} />
      </Margin>

      <Margin mt={20}>
        <UserEmailSubscriptionSettings user={user} />
      </Margin>

      {user.groups && user.groups.length > 0 && (
        <Margin mt={20}>
          <MyGroupsSection groups={user.groups} primaryGroup={user.primaryGroup} />
        </Margin>
      )}

      <Margin mt={20}>
        <DeleteMyAccountSection user={user} />
      </Margin>
    </>
  );
}
