import { useApolloClient } from "@apollo/client";
import React from "react";
import { FormGroup, Label } from "~/components/user/settings/index";
import { Field } from "react-final-form";
import TagsSelectContainer from "~/components/tag/TagsSelect";
import FieldValidationError from "~/components/form/FieldValidationError";
import { ITagType } from "~/types/types";
import {
  IGetDepartmentRelatedTagsQuery,
  IGetDepartmentRelatedTagsQueryVariables,
} from "~/lib/queries/__generated__/GetDepartmentRelatedTags.generated";
import GetDepartmentRelatedTags from "~/lib/queries/GetDepartmentRelatedTags";
import { OnChange } from "react-final-form-listeners";
import { FormattedMessage, useIntl } from "react-intl";
import * as palette from "@govlaunch/palette";
import { Phone } from "@govlaunch/core";

export default function InterestsField() {
  const apolloClient = useApolloClient();
  const intl = useIntl();

  const queryRelatedTags = async (departmentId: any) => {
    const queryData = await apolloClient.query<IGetDepartmentRelatedTagsQuery, IGetDepartmentRelatedTagsQueryVariables>(
      {
        query: GetDepartmentRelatedTags,
        variables: {
          departmentId,
        },
      },
    );

    return queryData.data.getDepartmentRelatedTags || [];
  };

  return (
    <Field name="interests">
      {({ input, meta }) => (
        <>
          <OnChange name="department">
            {async (value: any) => {
              if (value) {
                const relatedTags = await queryRelatedTags(value.objectID);
                const interests = relatedTags
                  .map((interest) => {
                    return {
                      _id: interest._id,
                      objectID: interest._id,
                      name: interest.name,
                    };
                  })
                  .filter(
                    (interest) =>
                      !(input.value || []).find(
                        (value: any) => value._id === interest._id || value.objectID === interest._id,
                      ),
                  );

                input.onChange((input.value || []).concat(interests));
              }
            }}
          </OnChange>
          <FormGroup>
            <Phone>
              {(isPhone: boolean) => (
                <Label
                  id="interests"
                  css={{
                    marginTop: 12,
                    marginBottom: "auto",
                    width: 140,
                    flexShrink: 0,
                    flexGrow: 0,
                    color: !input.value ? "#BE561E" : palette.darkGray,
                    paddingBottom: isPhone ? 16 : 0,
                  }}
                >
                  <FormattedMessage defaultMessage="Interests" id="dDDm0f" />
                </Label>
              )}
            </Phone>
            <div
              css={{
                width: "100%",
              }}
            >
              <div>
                <TagsSelectContainer
                  {...input}
                  type={[ITagType.Product, ITagType.Story, ITagType.Project]}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select up to twenty areas that interest you",
                    id: "IFObl4",
                  })}
                />
                <div>
                  <FieldValidationError meta={meta} />
                </div>
              </div>
            </div>
          </FormGroup>
        </>
      )}
    </Field>
  );
}
