import { Query } from "@apollo/client/react/components";
import { NextPage } from "next";
import { useRouter } from "next/router";
import React, { ReactElement } from "react";
import { useSelfie } from "~/components/auth/Auth";
import GatedContent from "~/components/GatedContent";
import UserPageSeo from "~/components/seo/UserPageSeo";
import UserProfile from "~/components/user/UserProfile";
import ProfileQuery from "~/lib/queries/ProfileQuery";
import { IGetProfileQuery, IGetProfileQueryVariables } from "~/lib/queries/__generated__/ProfileQuery.generated";
import Redirect from "~/lib/route/Redirect";
import safeGetQueryParameter from "~/lib/utils/safeGetQueryParameter";
import NavbarLayout from "~/src/components/navbar/NavbarLayout";

interface IUserProps {
  slug: string;
  tab?: string;
}

const User: NextPage<IUserProps> = ({ slug }: IUserProps): ReactElement => {
  const user = useSelfie();
  const router = useRouter();
  const queryTab = constructQueryTab(router.query.tab, router.query.sharing);

  return (
    <NavbarLayout>
      <Query<IGetProfileQuery, IGetProfileQueryVariables>
        query={ProfileQuery}
        variables={{
          slug,
        }}
      >
        {({ data, loading }) => {
          if (!data || loading || !data.user) {
            return null;
          }

          if (!user) {
            return (
              <>
                <UserPageSeo user={data.user} tab={queryTab} />
                <GatedContent hardRefreshAfterSignin={true} image="/static/gating/government-user-desktop.png" />
              </>
            );
          }

          // When no slug is returned it means that the logged user can't see the profile
          if (!data.user.slug) {
            return <Redirect to="/" />;
          }

          return (
            <>
              <UserPageSeo user={data.user} tab={queryTab} />
              <UserProfile user={data.user} />
            </>
          );
        }}
      </Query>
    </NavbarLayout>
  );
};

User.getInitialProps = ({ query }) => {
  const slug = safeGetQueryParameter(query, "userSlug");
  return {
    slug: slug.startsWith("@") ? slug.replace("@", "") : slug,
  };
};

function constructQueryTab(tab: string | string[] | undefined, sharing: string | string[] | undefined) {
  if (typeof sharing !== undefined && sharing) {
    return "sharing";
  }

  const queryTab = Array.isArray(tab) ? tab[0] : tab;
  return queryTab || "";
}

export default User;
