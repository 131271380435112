import { Mutation } from "@apollo/client/react/components";
import React, { useState } from "react";
import { Box, Button, Text } from "@govlaunch/web";
import { Input } from "@govlaunch/core";
import { Form as FinalForm } from "react-final-form";
import { Form, FormGroup, Section, Title } from "~/components/user/settings/index";
import { useToggle } from "react-use";
import { IGovernmentUser, IMutationDeleteProfileArgs, IPendingUser, IVendorUser } from "~/types/types";
import DeleteProfile from "~/lib/mutations/DeleteProfile";
import { IDeleteProfileMutation } from "~/lib/mutations/__generated__/DeleteProfile.generated";
import Alert from "~/components/makers/Alert";
import { FormattedMessage, useIntl } from "react-intl";

interface IDeleteMyAccountSectionProps {
  user:
    | Pick<IGovernmentUser, "__typename" | "_id">
    | Pick<IVendorUser, "__typename" | "_id" | "isDisruptor" | "showDeleteUserWarning">
    | Pick<IPendingUser, "__typename" | "_id">;
}

interface IConfirmDeleteSectionProps {
  submitting: boolean;
}

const ConfirmDelete = ({ submitting }: IConfirmDeleteSectionProps) => {
  const [deleteInputValue, setDeleteInputValue] = useState("");

  return (
    <FormGroup>
      <Input
        type="text"
        css={{ maxWidth: "250px" }}
        value={deleteInputValue}
        onChange={(event: { target: { value: React.SetStateAction<string> } }) => {
          setDeleteInputValue(event.target.value);
        }}
      />
      <Button variantColor="blue" ml={2} disabled={submitting || deleteInputValue !== "DELETE"} type="submit">
        <FormattedMessage defaultMessage="Confirm" id="N2IrpM" />
      </Button>
    </FormGroup>
  );
};

const DeleteProfileMutation = ({ user }: IDeleteMyAccountSectionProps) => {
  const intl = useIntl();

  return (
    <Mutation<IDeleteProfileMutation, IMutationDeleteProfileArgs>
      onCompleted={() => {
        window.location.href = "/";
      }}
      variables={{
        userId: user._id,
      }}
      mutation={DeleteProfile}
      onError={() => {
        alert(intl.formatMessage({ defaultMessage: 'Something went wrong. Please try again later!', id: 'kyJap5' }));
      }}
    >
      {(deleteUser) => (
        <FinalForm
          onSubmit={async () => {
            await deleteUser({ variables: { userId: user._id } });
          }}
        >
          {({ handleSubmit, submitting }) => (
            <Form onSubmit={handleSubmit}>
              {user.__typename === "VendorUser" && user.showDeleteUserWarning && (
                <Box mt={4}>
                  <Alert>
                    <FormattedMessage defaultMessage="Deleting your account will also cancel your company's paid subscription." id="QTdZpL" />
                  </Alert>
                </Box>
              )}
              <Text fontSize="md" fontWeight={400} mt={4}>
                <FormattedMessage defaultMessage="To confirm that you want to permanently delete your account, type DELETE in the box" id="O2BOO5" />
              </Text>
              <ConfirmDelete submitting={submitting} />
            </Form>
          )}
        </FinalForm>
      )}
    </Mutation>
  );
};

export default function DeleteMyAccountSection({ user }: IDeleteMyAccountSectionProps) {
  const [submitSectionVisible, toggleSubmitSectionVisible] = useToggle(false);

  return (
    <Section data-intercom-target="Delete User">
      <Title>
        <FormattedMessage defaultMessage="Delete account" id="wyxJrL" />
      </Title>
      <Text fontSize={["md", "md", "md"]} fontWeight={400} mt={4}>
        <FormattedMessage
          defaultMessage="Deleting your account will permanently remove all data and information related to your current account. If you
        decide to later on create another account, it will be a brand new account." id="QIQWXc"
        />
      </Text>
      <Button
        variantColor="red"
        mt={4}
        css={{
          backgroundColor: "#EB1501",
          color: "#fff",
        }}
        onClick={toggleSubmitSectionVisible}
      >
        <FormattedMessage defaultMessage="Permanently Delete Account" id="0ukaON" />
      </Button>
      {submitSectionVisible && <DeleteProfileMutation user={user} />}
    </Section>
  );
}
