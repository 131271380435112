import { Desktop } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import Link from "next/link";
import React, { ReactElement, SyntheticEvent } from "react";
import { Hover } from "react-powerplug";
import { IProduct, ITag } from "~/types/types";
import BookmarkButton from "~/components/BookmarkButton";
import { Margin } from "~/components/spacings";
import TagsPreview from "~/components/tag/TagsPreview";
import Dotdotdot from "~/components/text/Dotdotdot";

export interface IProductListItem {
  product: Pick<
    IProduct,
    | "_id"
    | "name"
    | "slug"
    | "logo"
    | "viewerDidBookmark"
    | "thumbnail"
    | "isDisruptor"
    | "description"
    | "upvotesCount"
    | "commentsCount"
    | "governmentsUsingCount"
  > & {
    tags: Pick<ITag, "_id" | "slug" | "name">[];
  };

  onBookmark: (e: SyntheticEvent) => void;
  displayBookmark?: boolean;
}

export default function ProductListItem({
  product,
  onBookmark,
  displayBookmark = true,
}: IProductListItem): ReactElement {
  const linkTo = `/products/${product.slug}`;

  return (
    <Hover>
      {({ hovered, bind }) => (
        <div
          css={{
            padding: 15,
            backgroundColor: hovered ? "rgba(250, 250, 250, 0.5)" : "inherit",
            display: "flex",
          }}
          {...bind}
        >
          <Link href={linkTo} passHref={true}>
            <a>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  css={{
                    height: 150,
                    width: 110,
                    pointerEvents: "none",
                    objectFit: "cover",
                    borderRadius: 5,
                  }}
                  src={product.thumbnail!}
                />
              </div>
            </a>
          </Link>

          <div
            css={{
              marginLeft: 15,
              display: "flex",
              flexDirection: "column",
              alignItems: "space-between",
              width: "100%",
            }}
          >
            <Link href={linkTo} passHref={true}>
              <a>
                <Margin mt={10}>
                  <div
                    css={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ProfilePicture size={28} image={product.logo} name={product.name} />
                    <h1
                      css={{
                        fontSize: 18,
                        margin: 0,
                        marginLeft: 10,
                        color: palette.text,
                      }}
                    >
                      <Dotdotdot clamp={2}>{product.name}</Dotdotdot>
                    </h1>
                  </div>
                </Margin>

                <Margin mt={10}>
                  <h2
                    css={{
                      fontSize: 14,
                      margin: 0,
                      color: palette.darkGray,
                      fontWeight: "normal",
                    }}
                  >
                    <Dotdotdot clamp={3}>{product.description}</Dotdotdot>
                  </h2>
                </Margin>
              </a>
            </Link>

            <div
              css={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginTop: "auto",
                marginBottom: 10,
                minHeight: 24,
              }}
            >
              <TagsPreview tags={product.tags} />

              {displayBookmark && hovered && (
                <Desktop>
                  <BookmarkButton onClick={onBookmark} bookmarked={product.viewerDidBookmark!} />
                </Desktop>
              )}
            </div>
          </div>
        </div>
      )}
    </Hover>
  );
}
