import { gql } from "@apollo/client";

export default gql`
  mutation UpdateProfileEmailSubscription($user: UserProfileSubscriptionInput!) {
    updateProfileEmailSubscription(user: $user) {
      ... on GovernmentUser {
        newsletterFrequency
        subscribedToNewsletter
        subscribedToCampaign
      }
      ... on VendorUser {
        newsletterFrequency
        subscribedToNewsletter
        subscribedToCampaign
      }
      ... on PendingUser {
        newsletterFrequency
        subscribedToNewsletter
        subscribedToCampaign
      }
    }
  }
`;
