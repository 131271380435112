import { IProjectCardFieldsFragment } from "@govlaunch/project/src/fragments/__generated__/ProjectCardFieldsFragment.generated";
import { Flex, PageContainer } from "@govlaunch/web";
import { SimpleGridProps } from "@govlaunch/web/src/grid/SimpleGrid";
import React, { MouseEvent } from "react";
import ShowMoreButtonWithIcon from "~/components/form/resources/ShowMoreButtonWithIcon";
import NoResultsFound from "~/src/shared/components/NoResultsFound";
import ProjectCards from "~/src/components/project/listing/ProjectCards";

export interface IProjectsListingProps {
  projects: IProjectCardFieldsFragment[];

  loading?: boolean;
  hasNextPage?: boolean;
  onLoadMore?: () => void;
  gridProps?: SimpleGridProps;
  withAddProjectSlot?: boolean;
  groupId?: string;
  onClickProject?: (e: MouseEvent<HTMLAnchorElement>, project: IProjectCardFieldsFragment, index: number) => void;
  onModalClose: () => void;
  projectNavigationLimit?: number;
  firstLocationId?: string;
  firstLocationCountry?: string;
  addProjectSlotText?: string;
}

export default function ProjectsListing({
  projects,
  loading,
  onLoadMore,
  hasNextPage,
  onModalClose,
  gridProps = {
    columns: [1, 1, 2, 3],
    spacingY: 6,
  },
  withAddProjectSlot = false,
  addProjectSlotText,
  groupId,
  onClickProject,
  projectNavigationLimit = Infinity,
  firstLocationId,
  firstLocationCountry,
}: IProjectsListingProps) {
  if (!loading && projects.length === 0) {
    return (
      <PageContainer>
        <NoResultsFound mt={32} />
      </PageContainer>
    );
  }

  return (
    <>
      <ProjectCards
        projects={projects}
        gridProps={gridProps}
        withAddProjectSlot={withAddProjectSlot}
        addProjectSlotText={addProjectSlotText}
        groupId={groupId}
        onClick={onClickProject}
        firstLocationId={firstLocationId}
        firstLocationCountry={firstLocationCountry}
        projectNavigationLimit={projectNavigationLimit}
        onModalClose={onModalClose}
      />

      {hasNextPage && (
        <Flex justifyContent="center" mt={6}>
          <ShowMoreButtonWithIcon
            loading={loading || false}
            onClick={() => {
              if (typeof onLoadMore !== "undefined") {
                onLoadMore();
              }
            }}
          />
        </Flex>
      )}
    </>
  );
}
