import { gql } from "@apollo/client";
import { ProjectCardFieldsFragment } from "@govlaunch/project";

export default gql`
  query UserProjects($slug: String!, $cursor: Cursor) {
    user(slug: $slug) {
      ... on GovernmentUser {
        _id
        projectsCount
        government {
          _id
          slug
          name
          state
        }
        projects(cursor: $cursor, limit: 8) {
          items {
            _id
            ...ProjectCardFields
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
        projectsFromGovernment {
          _id
          ...ProjectCardFields
        }
        relatedProjects {
          _id
          ...ProjectCardFields
        }
      }

      ... on VendorUser {
        _id
        projectsCount
        company {
          _id
          slug
          name
        }
        projects(cursor: $cursor, limit: 8) {
          items {
            _id
            ...ProjectCardFields
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }

      ... on PendingUser {
        _id
        projects(cursor: $cursor, limit: 8) {
          items {
            _id
            ...ProjectCardFields
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }

  ${ProjectCardFieldsFragment}
`;
