import { Mutation } from "@apollo/client/react/components";
import { Button, LoadingIndicator, Phone } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { IUser } from "@govlaunch/product/src/graphql/types";
import ProfilePicture from "@govlaunch/profile-picture";
import { Box, Flex, Text, Typeahead } from "@govlaunch/web";
import pick from "lodash/fp/pick";
import React, { ReactNode } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import locales from "~/intl/locales";
import useIntlValidationMessages from "~/lib/hooks/useIntlValidationMessages";
import useIsPhone from "~/lib/hooks/useIsPhone";
import UpdateProfile from "~/lib/mutations/UpdateProfile";
import {
  IUpdateProfileMutation,
  IUpdateProfileMutationVariables,
} from "~/lib/mutations/__generated__/UpdateProfile.generated";
import validate from "~/lib/utils/validate";
import { useIntlLocale } from "~/lib/withIntl";
import { COUNTRIES } from "~/src/utils/countries";
import { TZ } from "~/src/utils/timezones";
import { IGovernment, IGovernmentUser, IPendingUser, IUserPreferences, IVendorUser } from "~/types/types";
import { Margin } from "~/components/spacings";
import DepartmentField from "~/components/user/settings/DepartmentField";
import { CheckboxField, Form, FormGroup, Label, Section, TextField, Title } from "~/components/user/settings/index";
import InterestsField from "~/components/user/settings/InterestsField";

interface IMyDetailsSection {
  user:
    | (Pick<
        IGovernmentUser,
        | "__typename"
        | "_id"
        | "firstName"
        | "lastName"
        | "jobTitle"
        | "email"
        | "notificationEmail"
        | "searchable"
        | "socialMedia"
        | "department"
        | "interests"
        | "isSiteAdmin"
        | "newsletterFrequency"
        | "subscribedToNewsletter"
        | "country"
        | "language"
        | "timeZone"
      > & {
        government: Pick<IGovernment, "_id" | "logo" | "name"> | null;
        preferences: Pick<IUserPreferences, "showAsAuthorOnProjects">;
      })
    | (Pick<
        IVendorUser,
        | "__typename"
        | "_id"
        | "firstName"
        | "lastName"
        | "jobTitle"
        | "email"
        | "notificationEmail"
        | "searchable"
        | "socialMedia"
        | "department"
        | "interests"
        | "isSiteAdmin"
        | "newsletterFrequency"
        | "subscribedToNewsletter"
        | "country"
        | "language"
        | "timeZone"
      > & {
        preferences: Pick<IUserPreferences, "showAsAuthorOnProjects">;
      })
    | (Pick<
        IPendingUser,
        | "__typename"
        | "_id"
        | "firstName"
        | "lastName"
        | "jobTitle"
        | "email"
        | "notificationEmail"
        | "searchable"
        | "socialMedia"
        | "department"
        | "interests"
        | "isSiteAdmin"
        | "newsletterFrequency"
        | "subscribedToNewsletter"
        | "country"
        | "language"
        | "timeZone"
      > & {
        preferences: Pick<IUserPreferences, "showAsAuthorOnProjects">;
      });
}

const MyDetailsSection: React.FunctionComponent<IMyDetailsSection> = ({ user }) => {
  const isPhone = useIsPhone();
  const intl = useIntl();
  const intlValidationMessages = useIntlValidationMessages();
  const { locale, changeLocale } = useIntlLocale();

  const detailsValidationSchema = (user: Pick<IUser, "__typename">) => {
    if (user.__typename === "VendorUser") {
      return Yup.object().shape({
        firstName: Yup.string().required(intlValidationMessages.cantBeBlank),
        lastName: Yup.string().required(intlValidationMessages.cantBeBlank),
        jobTitle: Yup.string().nullable().trim(),
        email: Yup.string().email(intlValidationMessages.invalidEmailAddress).nullable(),
        notificationEmail: Yup.string().email(intlValidationMessages.invalidEmailAddress).nullable(),
        department: Yup.string().nullable(),
        linkedin: Yup.string().url(intlValidationMessages.invalidUrl).nullable(),
        twitter: Yup.string().url(intlValidationMessages.invalidUrl).nullable(),
        hideSearch: Yup.bool().required(),
        hideAuthor: Yup.bool().required(),
      });
    }

    return Yup.object().shape({
      firstName: Yup.string().required(intlValidationMessages.cantBeBlank),
      lastName: Yup.string().required(intlValidationMessages.cantBeBlank),
      jobTitle: Yup.string().nullable().trim(),
      email: Yup.string().email(intlValidationMessages.invalidEmailAddress).nullable(),
      notificationEmail: Yup.string().email(intlValidationMessages.invalidEmailAddress).nullable(),
      department: Yup.mixed().nullable(),
      interests: Yup.array().when("department", {
        is: (value: any) => {
          return value !== null;
        },
        then: Yup.array()
          .required()
          .min(1, intlValidationMessages.selectAMinOfXitems(1))
          .max(20, intlValidationMessages.selectUpToItems(20)),
        otherwise: Yup.array().nullable().max(20, intlValidationMessages.selectUpToItems(20)),
      }),
      linkedin: Yup.string().url(intlValidationMessages.invalidUrl).nullable(),
      twitter: Yup.string().url(intlValidationMessages.invalidUrl).nullable(),
      hideSearch: Yup.bool().required(),
      hideAuthor: Yup.bool().required(),
    });
  };

  return (
    <Section data-intercom-target="User Details">
      <Title>
        <FormattedMessage defaultMessage="My Details" id="mhrg7/" />
      </Title>

      <Mutation<IUpdateProfileMutation, IUpdateProfileMutationVariables>
        mutation={UpdateProfile}
        onCompleted={(data) => {
          if (data.updateProfile.language && data.updateProfile.language !== locale) {
            changeLocale(data.updateProfile.language);
          }
        }}
      >
        {(updateProfile) => (
          <FinalForm
            keepDirtyOnReinitialize={true}
            onSubmit={async (data) => {
              await updateProfile({
                variables: {
                  user: {
                    ...pick(["firstName", "jobTitle", "lastName", "notificationEmail"], data),
                    department: data.department ? data.department.objectID : null,
                    interests: data.interests.map(({ _id, objectID }: any) => _id || objectID),
                    socialMedia: {
                      facebook: user.socialMedia ? user.socialMedia.facebook : null,
                      instagram: user.socialMedia ? user.socialMedia.instagram : null,
                      youtube: user.socialMedia ? user.socialMedia.youtube : null,
                      linkedin: data.linkedin,
                      twitter: data.twitter,
                    },
                    searchable: !data.hideSearch,
                    preferences: {
                      showAsAuthorOnProjects: !data.hideAuthor,
                    },
                    country: data.country,
                    language: data.language,
                    timeZone: data.timeZone,
                  } as any,
                },
              });
            }}
            validate={validate(detailsValidationSchema(user))}
            initialValues={{
              firstName: user.firstName,
              lastName: user.lastName,
              jobTitle: (user.jobTitle || "") === "-" ? null : user.jobTitle,
              email: user.email,
              notificationEmail: user.notificationEmail,
              hideSearch: !user.searchable,
              linkedin: user.socialMedia ? user.socialMedia.linkedin : null,
              twitter: user.socialMedia ? user.socialMedia.twitter : null,
              department: user.department
                ? {
                    _id: user.department._id,
                    objectID: user.department._id,
                    name: user.department.name,
                  }
                : null,
              interests: (user.interests || []).map((interest) => {
                return {
                  _id: interest._id,
                  objectID: interest._id,
                  name: interest.name,
                };
              }),
              hideAuthor: user.isSiteAdmin ? true : !user.preferences.showAsAuthorOnProjects,
              country: user.country,
              language: user.language,
              timeZone: user.timeZone,
            }}
          >
            {({ handleSubmit, submitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormRow
                  labelId="firstName"
                  label={<FormattedMessage defaultMessage="First name" id="pONqz8" />}
                  field={<Field component={TextField} name="firstName" type="text" maxLength={20} />}
                  isTextField={true}
                />
                <FormRow
                  labelId="lastName"
                  label={<FormattedMessage defaultMessage="Last name" id="txUL0F" />}
                  field={<Field component={TextField} name="lastName" type="text" maxLength={40} />}
                  isTextField={true}
                />
                <FormRow
                  labelId="jobTitle"
                  label={<FormattedMessage defaultMessage="Job Title" id="w7sjvQ" />}
                  field={<Field component={TextField} name="jobTitle" type="text" maxLength={120} />}
                  colorCondition={!user.jobTitle || user.jobTitle === "-"}
                  isTextField={true}
                />
                <FormRow
                  labelId="email"
                  label={<FormattedMessage defaultMessage="E-mail" id="tkwCac" />}
                  field={<Field component={TextField} name="email" type="text" disabled={true} />}
                  isTextField={true}
                />
                <FormRow
                  labelId="notificationEmail"
                  label={<FormattedMessage defaultMessage="Notification E-mail" id="Op5r53" />}
                  field={<Field component={TextField} name="notificationEmail" type="text" />}
                  colorCondition={!user.notificationEmail}
                  isTextField={true}
                />

                {user.__typename !== "VendorUser" && (
                  <>
                    <DepartmentField />
                    <InterestsField />
                  </>
                )}

                <FormRow
                  labelId="linkedin"
                  label={<FormattedMessage defaultMessage="Linkedin Profile URL" id="MBUflk" />}
                  field={<Field component={TextField} name="linkedin" type="text" />}
                  colorCondition={!(user.socialMedia && user.socialMedia.linkedin)}
                  isTextField={true}
                />
                <FormRow
                  labelId="twitter"
                  label={<FormattedMessage defaultMessage="Twitter Profile URL" id="i2aOtT" />}
                  field={<Field component={TextField} name="twitter" type="text" />}
                  isTextField={true}
                />

                {user.__typename === "GovernmentUser" && user.government && (
                  <FormRow
                    labelId="government"
                    label={<FormattedMessage defaultMessage="Government" id="bh4rlK" />}
                    field={<GovernmentRowValue user={user} />}
                  />
                )}

                <FormRow
                  labelId="country"
                  label={<FormattedMessage defaultMessage="Country" id="vONi+O" />}
                  field={
                    <Field name="country">
                      {({ input }) => {
                        return (
                          <SimpleSelect
                            allowEmpty={true}
                            input={input}
                            items={COUNTRIES.map((country) => {
                              return { label: country.name, value: country.code };
                            })}
                          />
                        );
                      }}
                    </Field>
                  }
                />
                <FormRow
                  labelId="language"
                  label={<FormattedMessage defaultMessage="Language" id="y1Z3or" />}
                  field={
                    <Field name="language">
                      {({ input }) => {
                        return (
                          <SimpleSelect
                            allowEmpty={true}
                            input={input}
                            items={locales.map((language) => {
                              return { label: language.name, value: language.id };
                            })}
                          />
                        );
                      }}
                    </Field>
                  }
                />
                <FormRow
                  labelId="timeZone"
                  label={<FormattedMessage defaultMessage="Time Zone" id="jGWq1h" />}
                  field={
                    <div css={{ width: "100%" }}>
                      <Field name="timeZone">
                        {({ input }) => {
                          return (
                            <Typeahead
                              renderItem={TimeZoneTypeaheadItem}
                              items={TZ.filter(({ status }) => status === "Canonical").map((node) => {
                                return {
                                  label: node.name,
                                  value: node.name,
                                  offset: node.offset,
                                };
                              })}
                              onChange={(node) => input.onChange(node?.value || null)}
                              selectedItem={(() => {
                                const matches = TZ.filter(({ name }) => name === input.value);
                                if (matches && matches.length > 0) {
                                  const [node] = matches;
                                  return {
                                    label: node.name,
                                    value: node.name,
                                    offset: node.offset,
                                  };
                                }
                                return null;
                              })()}
                              placeholder="Type a timezone name"
                            />
                          );
                        }}
                      </Field>
                    </div>
                  }
                />
                <FormRow
                  labelId="search"
                  label={<FormattedMessage defaultMessage="Search" id="xmcVZ0" />}
                  field={
                    <Field
                      component={CheckboxField as any}
                      name="hideSearch"
                      id="hideSearch"
                      label={intl.formatMessage({
                        defaultMessage: "Hide Profile from Google and other search engines",
                        id: "ma/nUb",
                      })}
                      type="checkbox"
                    />
                  }
                />
                <FormRow
                  labelId="hideAuthorLabel"
                  label={<FormattedMessage defaultMessage="Project Creation" id="MYIKOT" />}
                  field={
                    <Field
                      component={CheckboxField as any}
                      name="hideAuthor"
                      id="hideAuthor"
                      label={intl.formatMessage({
                        defaultMessage: "Do not show my information on projects I create",
                        id: "omJ8c+",
                      })}
                      type="checkbox"
                      disabled={user.isSiteAdmin}
                    />
                  }
                />

                <div>
                  <Margin
                    mt={20}
                    css={{
                      display: "flex",
                      justifyContent: isPhone ? "center" : undefined,
                    }}
                  >
                    <Button disabled={submitting} type="submit" css={{ minWidth: 120 }}>
                      {submitting ? (
                        <Margin ml={2}>
                          <LoadingIndicator />
                        </Margin>
                      ) : (
                        <FormattedMessage defaultMessage="Update" id="BWpuKl" />
                      )}
                    </Button>
                  </Margin>
                </div>
              </Form>
            )}
          </FinalForm>
        )}
      </Mutation>
    </Section>
  );
};

export default MyDetailsSection;

interface ISimpleSelectProps {
  allowEmpty: boolean;
  items: ISimpleSelectItem[];
  input: any;
}

interface ISimpleSelectItem {
  label: string;
  value: string;
}

function SimpleSelect({ input, items, allowEmpty }: ISimpleSelectProps) {
  return (
    <select
      {...input}
      css={{
        height: 42,
        padding: "0 10px",
        fontSize: 14,
        backgroundColor: palette.white,
        border: `1px solid ${palette.lightestGray}`,
        borderRadius: 5,
        margin: 0,
        marginRight: 5,
        width: "100%",
        outline: 0,
        ":focus": {
          borderColor: palette.lightGray,
        },
        "::placeholder": {
          color: palette.sealBlue,
        },
      }}
    >
      {allowEmpty && <SelectOption label="" value="" key="Empty" />}
      {items.map((item) => {
        return <SelectOption label={item.label} value={item.value} key={item.value} />;
      })}
    </select>
  );
}
interface ISelectOptionProps {
  label: string;
  value: string;
}

function SelectOption({ label, value }: ISelectOptionProps) {
  return <option value={value}>{label}</option>;
}

interface IFormRowProps {
  labelId: string;
  label: ReactNode;
  field: ReactNode;
  colorCondition?: boolean;
  isTextField?: boolean;
}

function FormRow({ labelId, label, field, colorCondition, isTextField }: IFormRowProps) {
  return (
    <Label
      id={labelId}
      css={
        colorCondition
          ? {
              color: "#BE561E",
              width: "100%",
            }
          : {
              color: palette.darkGray,
              width: "100%",
            }
      }
    >
      <Phone>
        {(isPhone: boolean) => (
          <FormGroup>
            <div
              css={{
                width: 170,
                marginRight: 10,
                paddingBottom: isPhone || isTextField ? 16 : 0,
              }}
            >
              {label}
            </div>
            {field}
          </FormGroup>
        )}
      </Phone>
    </Label>
  );
}

function GovernmentRowValue({ user }: IMyDetailsSection) {
  return (
    <div
      css={{
        width: "100%",
        paddingBottom: 10,
        paddingLeft: 0,
        borderBottom: `1px solid ${palette.lightestGray}`,
      }}
    >
      {(user as IGovernmentUser).government && (
        <div
          css={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ProfilePicture
            image={(user as IGovernmentUser).government!.logo}
            name={(user as IGovernmentUser).government!.name}
            xsmall={true}
          />
          <span
            css={{
              color: palette.text,
              fontSize: 14,
              marginLeft: 10,
            }}
          >
            {(user as IGovernmentUser).government!.name}
          </span>
        </div>
      )}
    </div>
  );
}

interface ITimeZoneTypeaheadItemProps {
  item: {
    label: string;
    value: string;
    offset: string;
  };
  active: boolean;
}

function TimeZoneTypeaheadItem({ item, active }: ITimeZoneTypeaheadItemProps) {
  return (
    <Flex
      as="li"
      py={3}
      px={4}
      bg={active ? "blue.600" : "white"}
      color={active ? "white" : "black"}
      cursor="pointer"
      align="center"
    >
      <Box overflow="hidden">
        <Text fontSize="md" mb={0} isTruncated={true}>
          <i>{item.offset}</i> {item.label}
        </Text>
      </Box>
    </Flex>
  );
}
