import { Mutation } from "@apollo/client/react/components";
import React, { useState, ReactElement } from "react";
import omit from "lodash/fp/omit";
import filter from "lodash/fp/filter";
import identity from "lodash/fp/identity";
import * as palette from "@govlaunch/palette";
import { Modal, Button, ModalClose, BlockBodyOverflow } from "@govlaunch/core";
import { useToggle } from "react-use";
import Switch from "react-switch";
import SaveUserSharing from "~/lib/mutations/SaveUserSharing";
import { IGovernmentUser, IUserSharingOptions } from "~/types/types";
import getSharingUrl from "~/components/user/sharing/getSharingUrl";
import {
  ISaveUserSharingMutation,
  ISaveUserSharingMutationVariables,
} from "~/lib/mutations/__generated__/SaveUserSharing.generated";
import { FormattedMessage, useIntl } from "react-intl";

interface ISharingSettingsModal {
  user: Pick<IGovernmentUser, "_id" | "slug" | "enabledSharing" | "sharing">;
  isOpen: boolean;
  onRequestClose: () => any;
}

type ISharingOptions = Pick<IUserSharingOptions, "projects" | "resources" | "stories">;

interface IToggle {
  value: keyof ISharingOptions;
  description: string | ReactElement;
  sharing: ISharingOptions;
  setSharing: (value?: any) => any;
  enabledSharing: boolean;
}

const SharingSettingsModal: React.FunctionComponent<ISharingSettingsModal> = ({
  user,
  isOpen,
  onRequestClose,
  ...props
}) => {
  const [enabledSharing, toggleEnabledSharing] = useToggle(user.enabledSharing || false);
  const [sharing, setSharing] = useState<ISharingOptions>(omit(["__typename"], user.sharing));
  const [saving, setSaving] = useState(false);
  const intl = useIntl();

  function handleSetSharing(toggleEnabledSharing: (value?: any) => void) {
    return (options: any) => {
      if (filter(identity, options).length === 0) {
        toggleEnabledSharing();
      }

      setSharing(options);
    };
  }

  return (
    <Modal
      {...props}
      isOpen={isOpen}
      onRequestClose={() => {
        if (!saving) {
          onRequestClose();
        }
      }}
      ariaHideApp={false}
      overlayStyle={{
        background: "rgba(255, 255, 255, 0.6)",
      }}
      style={{
        boxShadow: "0 0 20px 0 #e1e1e1",
        border: null,
      }}
    >
      {isOpen && <BlockBodyOverflow />}

      <ModalClose
        onClick={onRequestClose}
        style={{
          cursor: "pointer",
        }}
      />

      <div
        css={{
          padding: 20,
          width: 450,
        }}
      >
        <div
          css={{
            display: "grid",
            rowGap: 15,
          }}
        >
          <div>
            <div
              css={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3
                css={{
                  display: "flex",
                  alignItems: "center",
                  color: palette.darkGray,
                  margin: 0,
                  fontWeight: 500,
                  fontSize: 21,
                }}
              >
                <FormattedMessage defaultMessage="Sharing" id="XfKNCg" />
                <span
                  css={{
                    marginLeft: 5,
                    fontSize: 14,
                    color: enabledSharing ? "#178B61" : "#B44F4F",
                  }}
                >
                  <Switch
                    uncheckedIcon={
                      <div
                        css={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 15,
                          color: palette.lightRed,
                          paddingRight: 10,
                        }}
                      >
                        off
                      </div>
                    }
                    checkedIcon={
                      <div
                        css={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 15,
                          color: "#fff",
                          paddingLeft: 10,
                        }}
                      >
                        on
                      </div>
                    }
                    handleDiameter={22}
                    height={26}
                    width={65}
                    onChange={(checked) => {
                      toggleEnabledSharing();

                      if (checked) {
                        setSharing({
                          projects: true,
                          stories: true,
                          resources: true,
                        });
                      } else {
                        setSharing({
                          projects: false,
                          stories: false,
                          resources: false,
                        });
                      }
                    }}
                    checked={enabledSharing}
                    onColor={palette.greenSuccess}
                    offColor={palette.red}
                  />
                </span>
              </h3>
            </div>
          </div>

          {enabledSharing ? (
            <p
              css={{
                color: palette.darkGray,
                fontSize: 14,
                margin: 0,
              }}
            >
              <FormattedMessage defaultMessage="Select the items below to display in your public share page" id="6kIwcu" />
              {": "}
              <a
                css={{
                  color: "#005AEE",
                }}
                href={getSharingUrl(user.slug as string)}
                rel="noopener noreferrer"
                target="_blank"
              >
                {getSharingUrl(user.slug as string)}
              </a>
            </p>
          ) : (
            <p
              css={{
                color: palette.darkGray,
                fontSize: 14,
                margin: 0,
              }}
            >
              <FormattedMessage defaultMessage="Turn on sharing to create your custom page and then select what you want to share. It is that simple! Your User's unique URL" id="H45RTJ" />
              {": "}
              <a
                css={{
                  color: "#005AEE",
                }}
                href={getSharingUrl(user.slug as string)}
                rel="noopener noreferrer"
                target="_blank"
              >
                {getSharingUrl(user.slug as string)}
              </a>
            </p>
          )}

          <div>
            <p
              css={{
                color: palette.darkGray,
                fontSize: 14,
                margin: 0,
                marginBottom: 5,
              }}
            >
              <strong>
                <FormattedMessage defaultMessage="Public" id="uhu5aG" />:
              </strong>{" "}
              <FormattedMessage defaultMessage="Anybody can view (including guests in Govlaunch)" id="dF5LfH" />
            </p>
            <p
              css={{
                color: palette.darkGray,
                fontSize: 14,
                margin: 0,
              }}
            >
              <strong>
                <FormattedMessage defaultMessage="Private" id="viXE32" />:
              </strong>{" "}
              <FormattedMessage defaultMessage="Only verified users of the Govlaunch community can view" id="bQmHhu" />
            </p>
          </div>

          <div
            css={{
              opacity: enabledSharing ? 1 : 0.3,
              display: "grid",
              rowGap: 10,
            }}
          >
            <Toggle
              value="stories"
              description={
                <span>
                  <FormattedMessage defaultMessage="Stories" id="Ldxym4" />:{" "}
                  <span
                    css={{
                      fontSize: 11,
                    }}
                  >
                    (
                    <FormattedMessage defaultMessage="only stories marked as public will be visible" id="UPxtYU" />)
                  </span>
                </span>
              }
              sharing={sharing}
              setSharing={handleSetSharing(toggleEnabledSharing)}
              enabledSharing={enabledSharing}
            />
            <Toggle
              value="projects"
              description={
                <span>
                  <FormattedMessage defaultMessage="Projects" id="UxTJRa" />:{" "}
                  <span
                    css={{
                      fontSize: 11,
                    }}
                  >
                    (
                    <FormattedMessage defaultMessage="only projects marked as public will be visible" id="8uWZ5k" />)
                  </span>
                </span>
              }
              sharing={sharing}
              setSharing={handleSetSharing(toggleEnabledSharing)}
              enabledSharing={enabledSharing}
            />
            <Toggle
              value="resources"
              description={intl.formatMessage({
                defaultMessage: 'Resources', id: 'c/KktL',
              })}
              sharing={sharing}
              setSharing={handleSetSharing(toggleEnabledSharing)}
              enabledSharing={enabledSharing}
            />
          </div>

          <Mutation<ISaveUserSharingMutation, ISaveUserSharingMutationVariables> mutation={SaveUserSharing}>
            {(saveUserSharing) => (
              <Button
                disabled={saving}
                size="small"
                color={palette.innovatorBlue}
                css={{
                  userSelect: "none",
                  marginTop: 10,
                }}
                onClick={() => {
                  setSaving(true);

                  saveUserSharing({
                    variables: {
                      userSlug: user.slug as string,
                      sharing: {
                        posts: false,
                        ...(sharing || {}),
                      },
                      enableSharing: enabledSharing,
                    },
                  }).then(() => {
                    setSaving(false);
                    onRequestClose();
                  });
                }}
              >
                {saving ? <FormattedMessage defaultMessage="Saving" id="QLkzoS" /> : <FormattedMessage defaultMessage="Save" id="jvo0vs" />}
              </Button>
            )}
          </Mutation>
        </div>
      </div>
    </Modal>
  );
};

const Toggle: React.FunctionComponent<IToggle> = ({ value, description, sharing, setSharing, enabledSharing }) => {
  return (
    <div>
      <label
        css={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Switch
          uncheckedIcon={
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 10,
                color: palette.lightRed,
                paddingRight: 15,
              }}
            >
              off
            </div>
          }
          checkedIcon={
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 10,
                color: "#fff",
                paddingLeft: 15,
              }}
            >
              on
            </div>
          }
          offColor={palette.red}
          handleDiameter={22}
          height={30}
          width={80}
          onColor={palette.greenSuccess}
          onChange={() => {
            return setSharing(
              omit("__typename", {
                ...sharing,
                [value]: !sharing[value],
              }),
            );
          }}
          checked={enabledSharing && Boolean(sharing[value])}
        />
        <span
          css={{
            marginLeft: 10,
            color: palette.darkGray,
            fontSize: 16,
          }}
        >
          {description}
        </span>
      </label>
    </div>
  );
};

export default SharingSettingsModal;
