import { Box, Modal } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelfie } from "~/components/auth/Auth";
import ModalCloseIcon from "~/components/icons/ModalCloseIcon";
import { Margin } from "~/components/spacings";

interface IUserNotVerifiedProps {
  isOpen: boolean;
  overlayStyle?: any;
  style?: any;
  onRequestClose?: () => any;
}

export default function UserNotVerified({ isOpen, onRequestClose, overlayStyle, style }: IUserNotVerifiedProps) {
  const user = useSelfie();

  const isCitizen = user && user.role === "CITIZEN";

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      overlayStyle={overlayStyle}
      style={{
        background: "#fff",
        zIndex: 0,
        boxShadow: "0 30px 40px 0 rgba(34, 35, 40, 0.2)",
        ...style,
      }}
    >
      <Box width={[360, 710]}>
        {onRequestClose && (
          <span
            role="button"
            aria-label="Close"
            onClick={onRequestClose}
            css={{
              position: "absolute",
              zIndex: 9999,
              top: 0,
              right: 0,
              cursor: "pointer",
              width: 45,
              height: 45,
              paddingTop: 20,
              paddingRight: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ModalCloseIcon />
          </span>
        )}
      </Box>

      <Box
        width={[360, 710]}
        css={{
          background: "#fff",
          zIndex: 0,
          boxShadow: "0 30px 40px 0 rgba(34, 35, 40, 0.2)",
          borderRadius: 4,
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            padding: "64px 32px 120px 34px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "bottom right",
            backgroundImage: `url("/static/gating/verified-members-only.png")`,
            backgroundPositionX: 45,
          }}
        >
          <Margin mb={24}>
            <h1
              css={{
                fontSize: 26,
                margin: 0,
              }}
            >
              {isCitizen ? (
                <FormattedMessage defaultMessage="You don't have access to this content" id="WANdbg" />
              ) : (
                <FormattedMessage
                  defaultMessage="Oops! This content is only available to our verified community members."
                  id="x3XSwA"
                />
              )}
            </h1>
          </Margin>
          <Margin mb={48}>
            <p
              css={{
                margin: 0,
                maxWidth: 400,
              }}
            >
              {isCitizen ? (
                <>
                  <FormattedMessage defaultMessage="If you think this a mistake, please contact us at" id="FrjQba" />{" "}
                  <a
                    href="mailto:hello@govlaunch.com"
                    css={{
                      color: palette.innovatorBlue,
                    }}
                  >
                    hello@govlaunch.com
                  </a>
                </>
              ) : (
                <FormattedMessage defaultMessage="Thank you for your interest in Govlaunch." id="tIv2yE" />
              )}
            </p>
          </Margin>
          <Margin mb={12}>
            <Link href="/" passHref={true}>
              <a
                css={{
                  fontSize: 18,
                  color: palette.innovatorBlue,
                }}
              >
                <FormattedMessage defaultMessage="Go to the homepage" id="eyGrVk" />
              </a>
            </Link>
          </Margin>

          <a
            href="https://help.govlaunch.com/en/collections/2190109-what-is-govlaunch"
            target="_blank"
            rel="noopener noreferrer"
            css={{
              margin: 0,
              color: palette.innovatorBlue,
              fontSize: 14,
            }}
          >
            <FormattedMessage defaultMessage="Click here to learn more about us" id="6jw/cR" />
          </a>
        </div>
      </Box>
    </Modal>
  );
}
