import React from "react";

export default function InnovatorsIcon(props: any) {
  return (
    <svg viewBox="0 0 15 15" width={15} height={15} {...props}>
      <path
        d="M5.772 3.96c.193-.45.552-.808 1.002-1.001.227-.097.468-.147.726-.147.258 0 .5.05.727.147.45.194.808.553 1.002 1.002.097.227.146.468.146.727 0 .257-.049.498-.146.725a1.88 1.88 0 0 1-.405.597c-.172.171-.374.31-.597.406-.23.098-.477.148-.727.147-.258 0-.499-.05-.726-.147a1.919 1.919 0 0 1-1.002-1.003 1.805 1.805 0 0 1-.147-.725c0-.259.049-.5.147-.727M5.986 15V9.8c0-.798.64-1.444 1.43-1.444h.19c.787 0 1.426.644 1.426 1.44v5.199c3.808-.798 6.558-4.512 5.86-8.73-.505-3.04-2.888-5.526-5.884-6.117C4.205-.796 0 2.894 0 7.578 0 11.239 2.57 14.293 5.986 15"
        fill="#005AEE"
        fillRule="nonzero"
      />
    </svg>
  );
}
