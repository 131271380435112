import { gql } from "@apollo/client";

export default gql`
  query RecentlyJoinedGovernments {
    recentlyJoinedGovernments {
      _id
      name
      logo
      slug
      city {
        _id
        name
        country
        countryName
      }
    }
  }
`;
