import React, { ReactElement } from "react";
import { BlockBodyOverflow } from "@govlaunch/core";
import { SigninModal } from "~/src/components/auth/SignInModal";
import { useSelfie } from "~/components/auth/Auth";
import UserNotVerified from "~/components/UserNotVerified";

interface IGatedContentProps {
  children?: any;
  image: string;
  onSignIn?: () => void;
  isOpen?: boolean;
  onRequestClose?: () => any;
  hardRefreshAfterSignin?: boolean;
}

export default function GatedContent({
  image,
  isOpen = true,
  hardRefreshAfterSignin,
  onSignIn,
  onRequestClose = defaultOnRequestClose,
}: IGatedContentProps): ReactElement | null {
  const user = useSelfie();

  return (
    <>
      <BlockBodyOverflow />

      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: "calc(100vh - 30%)",
          textAlign: "center",
          marginTop: 85,
          filter: "blur(6px)",
        }}
      >
        <img
          alt="gating image"
          src={image}
          css={{
            maxWidth: "100%",
            width: "100%",
            height: "100%",
            objectFit: "contain",
            objectPosition: "top",
          }}
        />
      </div>

      <div
        css={{
          width: "100%",
          marginTop: 85,
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {user && !user.verified && <UserNotVerified isOpen={isOpen} onRequestClose={onRequestClose} />}
        {!user && (
          <SigninModal
            isOpen={isOpen}
            hardRefreshAfterSignin={hardRefreshAfterSignin}
            onSignIn={onSignIn}
            onRequestClose={onRequestClose}
          />
        )}
      </div>
    </>
  );
}

function defaultOnRequestClose() {
  if (typeof window !== "undefined") {
    return window.history.back();
  }
}
