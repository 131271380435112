import { Col, Grid, RowWrap } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Box, CountryFlag, TCountryCode } from "@govlaunch/web";
import chunk from "lodash/fp/chunk";
import Link from "next/link";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useIsTablet from "~/lib/hooks/useIsTablet";
import useShowMoreCounter from "~/lib/hooks/useShowMoreCounter";
import { IRecentlyJoinedGovernmentsQuery } from "~/lib/queries/__generated__/RecentlyJoinedGovernments.generated";
import Tooltip from "~/components/Tooltip";

interface IGovernmentsBoxProps {
  title: string;
  governments: IRecentlyJoinedGovernmentsQuery["recentlyJoinedGovernments"];
}

export default function GovernmentsBox({ title, governments, ...props }: IGovernmentsBoxProps) {
  const isTablet = useIsTablet();
  const { offset, hasMore, showMore } = useShowMoreCounter(governments.length, isTablet ? 3 : 8);

  return (
    <div
      {...props}
      css={{
        backgroundColor: palette.white,
        borderRadius: 5,
        border: `solid 1px ${palette.lightestGray}`,
      }}
    >
      <div
        css={{
          padding: 15,
          borderBottom: `solid 1px ${palette.lightestGray}`,
        }}
      >
        <h2
          css={{
            textTransform: "uppercase",
            fontSize: 12,
            color: palette.darkGray,
            letterSpacing: 0.5,
            fontWeight: 500,
            margin: 0,
          }}
        >
          {title}
        </h2>
      </div>

      <Grid gutter={15}>
        {chunk(4, governments.slice(0, offset)).map((row, index) => {
          return (
            <RowWrap
              key={index}
              mt={15}
              mb={15}
              css={{
                justifyContent: row.length % 4 === 0 ? "center" : "flex-start",
              }}
            >
              {row.map((government) => {
                return (
                  <Col
                    key={government._id}
                    width={3 / 12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Link
                      href="/governments/[governmentSlug]/[[...content]]"
                      as={`/governments/${government.slug}/settings`}
                      passHref={true}
                    >
                      <a
                        css={{
                          display: "inline-flex",
                        }}
                      >
                        <Tooltip
                          backgroundColor={palette.innovatorBlue}
                          borderColor={palette.innovatorBlue}
                          render={() => (
                            <div
                              css={{
                                padding: 6,
                                color: palette.white,
                                maxWidth: 200,
                                textAlign: "center",
                                fontSize: 14,
                                fontWeight: 600,
                              }}
                            >
                              {government.name} - {government.city.countryName}
                            </div>
                          )}
                        >
                          {({ innerRef, bind }: any) => (
                            <Box position="relative">
                              <ProfilePicture
                                {...bind}
                                innerRef={innerRef}
                                size={60}
                                image={government.logo}
                                name={government.name}
                              />

                              <Box position="absolute" bottom={1} right={-3}>
                                <CountryFlag
                                  countryCode={government.city.country as TCountryCode}
                                  width={24}
                                  height={14}
                                />
                              </Box>
                            </Box>
                          )}
                        </Tooltip>
                      </a>
                    </Link>
                  </Col>
                );
              })}
            </RowWrap>
          );
        })}
      </Grid>

      {hasMore && <ShowMore onClick={showMore} />}
    </div>
  );
}

const ShowMore = (props: any) => {
  const intl = useIntl();

  return (
    <div
      role="button"
      aria-label={intl.formatMessage({
        defaultMessage: 'Show more', id: 'aWpBzj',
      })}
      css={{
        padding: 15,
        textTransform: "uppercase",
        color: palette.darkGray,
        fontSize: 12,
        textAlign: "center",
        cursor: "pointer",
        borderTop: `solid 1px ${palette.lightestGray}`,
        "&:hover": {
          color: palette.darkGray,
        },
      }}
      {...props}
    >
      <FormattedMessage defaultMessage="Show more" id="aWpBzj" />
    </div>
  );
};
