import { Col, Grid, RowWrap } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import chunk from "lodash/fp/chunk";
import Link from "next/link";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useIsTablet from "~/lib/hooks/useIsTablet";
import useShowMoreCounter from "~/lib/hooks/useShowMoreCounter";
import { IUser } from "~/types/types";
import AnonymousUser from "~/components/icons/AnonymousUser";
import Tooltip from "~/components/Tooltip";

interface IUsersBox {
  title: string;
  users: Pick<IUser, "_id" | "avatar" | "slug" | "fullName" | "jobTitle" | "firstName">[];
  dataIntercomTarget?: string | null;
}

const UsersBox: React.FunctionComponent<IUsersBox> = ({ title, users, dataIntercomTarget }) => {
  const isTablet = useIsTablet();
  const { offset, hasMore, showMore } = useShowMoreCounter(users.length, isTablet ? 3 : 8);

  return (
    <div
      data-intercom-target={dataIntercomTarget}
      css={{
        backgroundColor: palette.white,
        borderRadius: 5,
        border: `solid 1px ${palette.lightestGray}`,
      }}
    >
      <div
        css={{
          padding: 15,
          borderBottom: `solid 1px ${palette.lightestGray}`,
        }}
      >
        <h2
          css={{
            textTransform: "uppercase",
            fontSize: 12,
            color: palette.darkGray,
            letterSpacing: 0.5,
            fontWeight: 500,
            margin: 0,
          }}
        >
          {title}
        </h2>
      </div>

      <Grid gutter={15}>
        {chunk(4, users.slice(0, offset)).map((row, index) => {
          return (
            <RowWrap
              key={index}
              mt={15}
              mb={15}
              css={{
                justifyContent: row.length % 4 === 0 ? "center" : "flex-start",
              }}
            >
              {row.map((user) => {
                return (
                  <Col
                    key={user._id}
                    width={3 / 12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {user.slug ? (
                      <Link passHref={true} href="/[userSlug]/[[...tab]]" as={`/@${user.slug}`}>
                        <a
                          css={{
                            display: "inline-flex",
                          }}
                        >
                          <Tooltip
                            backgroundColor={palette.innovatorBlue}
                            borderColor={palette.innovatorBlue}
                            render={() => (
                              <div
                                css={{
                                  padding: 10,
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  color: palette.white,
                                  maxWidth: 200,
                                }}
                              >
                                <span
                                  css={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                  }}
                                >
                                  {user.fullName || user.firstName}
                                </span>

                                <span
                                  css={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    marginTop: 5,
                                  }}
                                >
                                  {user.jobTitle}
                                </span>
                              </div>
                            )}
                          >
                            {({ innerRef, bind }: any) => (
                              <ProfilePicture
                                {...bind}
                                innerRef={innerRef}
                                size={60}
                                image={user.avatar}
                                name={user.fullName}
                              />
                            )}
                          </Tooltip>
                        </a>
                      </Link>
                    ) : (
                      <div
                        css={{
                          display: "inline-flex",
                        }}
                      >
                        <AnonymousUser width={60} height={60} />
                      </div>
                    )}
                  </Col>
                );
              })}
            </RowWrap>
          );
        })}
      </Grid>

      {hasMore && <ShowMore onClick={showMore} />}
    </div>
  );
};

const ShowMore = (props: any) => {
  const intl = useIntl();

  return (
    <div
      role="button"
      aria-label={intl.formatMessage({
        defaultMessage: 'Show more', id: 'aWpBzj',
      })}
      css={{
        padding: 15,
        textTransform: "uppercase",
        color: palette.sealBlue,
        fontSize: 12,
        textAlign: "center",
        cursor: "pointer",
        borderTop: `solid 1px ${palette.lightestGray}`,
        "&:hover": {
          color: palette.darkGray,
        },
      }}
      {...props}
    >
      <FormattedMessage defaultMessage="Show more" id="aWpBzj" />
    </div>
  );
};

export default UsersBox;
