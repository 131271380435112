import React from "react";
import * as palette from "@govlaunch/palette";
import { Field } from "react-final-form";
import { FormattedMessage } from "react-intl";
import { Checkbox } from "~/components/user/settings/SubscribedToNewsletter";

export default function SubscribedToCampaign() {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",

        width: "100%",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          css={{
            fontSize: 20,
          }}
        >
          <label htmlFor="subscribedToCampaign">
            <FormattedMessage defaultMessage="Govlaunch Updates" id="f7In8V" />
          </label>
        </div>
        <div
          css={{
            color: palette.darkGray,
            fontSize: 16,
          }}
        >
          <FormattedMessage defaultMessage="Periodic product releases, company announcements and other fun stuff! 🎉" id="tMcH18" />
        </div>
      </div>
      <Field name="subscribedToCampaign" type="checkbox">
        {({ input }) => (
          <div
            css={{
              display: "flex",
              alignItems: "flex-start",
              margin: 4,
            }}
          >
            <div
              css={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox id="subscribedToCampaign" type="checkbox" {...input} />
            </div>
          </div>
        )}
      </Field>
    </div>
  );
}
