import { Mutation, Query } from "@apollo/client/react/components";
import { Col, Grid, RowWrap } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Box, CountryFlag, TCountryCode, Tooltip } from "@govlaunch/web";
import { WithRouterProps } from "next/dist/client/with-router";
import { default as Head } from "next/head";
import Link from "next/link";
import { withRouter } from "next/router";
import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useIsMobile from "~/lib/hooks/useIsMobile";
import SetProfilePicture from "~/lib/mutations/SetProfilePicture";
import SetUserResources from "~/lib/mutations/SetUserResources";
import {
  ISetProfilePictureMutation,
  ISetProfilePictureMutationVariables,
} from "~/lib/mutations/__generated__/SetProfilePicture.generated";
import {
  ISetUserResourcesMutation,
  ISetUserResourcesMutationVariables,
} from "~/lib/mutations/__generated__/SetUserResources.generated";
import { IFollowingFieldsFragment } from "~/lib/queries/fragments/__generated__/FollowingFields.generated";
import RecentlyJoinedGovernments from "~/lib/queries/RecentlyJoinedGovernments";
import {
  IRecentlyJoinedGovernmentsQuery,
  IRecentlyJoinedGovernmentsQueryVariables,
} from "~/lib/queries/__generated__/RecentlyJoinedGovernments.generated";
import {
  ICity,
  ICompany,
  IDownloadResource,
  IFlatResource,
  IGovernment,
  IGovernmentUser,
  IGroup,
  ILinkResource,
  IPendingUser,
  ITextResource,
  IUser,
  IVendorUser,
} from "~/types/types";
import WriteContentBox from "~/components/feed/WriteContentBox";
import HorizontalLine from "~/components/HorizontalLine";
import InnovatorsIcon from "~/components/icons/InnovatiorsIcon";
import ChangeableProfilePicture from "~/components/profile/ChangeableProfilePicture";
import NewLinkTab from "~/components/profile/NewLinkTab";
import { FollowButton, Following } from "~/components/profile/profile-components";
import ProfileNavbar from "~/components/profile/ProfileNavbar";
import Resources from "~/components/profile/Resources";
import ResourcesForm, { ResourcesFormContextProvider } from "~/components/profile/ResourcesForm";
import { Margin } from "~/components/spacings";
import GovernmentsBox from "~/components/user/GovernmentsBox";
import SharingBox from "~/components/user/sharing/SharingBox";
import SharingSettingsModal from "~/components/user/sharing/SharingSettingsModal";
import { PostsTab, ProjectsTab, SavedTab, StoriesTab } from "~/components/user/Tabs";
import UserProfileSettings from "~/components/user/UserProfileSettings";
import UsersBox from "~/components/user/UsersBox";
import countUserBookmarks from "~/components/user/utils/countUserBookmarks";

type IUserProps = Pick<
  IUser,
  | "_id"
  | "slug"
  | "searchable"
  | "isSelfie"
  | "fullName"
  | "avatar"
  | "jobTitle"
  | "isFollowing"
  | "linkedinUrl"
  | "postsCount"
  | "storiesCount"
  | "projectsCount"
  | "savedProductsCount"
  | "savedProjectsCount"
  | "savedPostsCount"
  | "savedStoriesCount"
> & {
  following: IFollowingFieldsFragment[];
  groups: Pick<IGroup, "_id" | "slug" | "logo" | "name">[];
};

interface IUserProfileProps {
  user:
    | (IUserProps &
        Pick<IGovernmentUser, "__typename" | "resources" | "enabledSharing" | "isInnovator" | "sharing"> & {
          government:
            | (Pick<IGovernment, "_id" | "slug" | "name" | "logo"> & {
                city: Pick<ICity, "country" | "countryName">;
              } & {
                members:
                  | Pick<IGovernmentUser, "_id" | "slug" | "avatar" | "fullName" | "jobTitle" | "firstName">[]
                  | null;
              })
            | null;
        })
    | (IUserProps &
        Pick<IVendorUser, "__typename" | "isDisruptor"> & {
          company:
            | (Pick<ICompany, "_id" | "slug" | "name" | "logo"> & {
                members: Pick<IVendorUser, "_id" | "slug" | "fullName" | "avatar" | "jobTitle" | "firstName">[] | null;
              })
            | null;
        })
    | (IUserProps & Pick<IPendingUser, "__typename">);
}

function getDefaultTab(
  tab: string | string[] | undefined,
  user: Pick<IUser, "__typename" | "projectsCount" | "storiesCount"> | null,
): string {
  if (tab) {
    return Array.isArray(tab) ? tab[0] : tab;
  }

  if (user && user.__typename === "GovernmentUser") {
    return "posts";
  }

  if (user && (user.__typename === "PendingUser" || user.__typename === "VendorUser") && (user.storiesCount || 0) > 0) {
    return "stories";
  }

  if (
    (user && user.__typename === "PendingUser") ||
    (user && user.__typename === "VendorUser" && (user.projectsCount || 0) === 0)
  ) {
    return "saved";
  }

  return "projects";
}

function UserProfile({ user, router }: IUserProfileProps & WithRouterProps): ReactElement {
  const tab = router ? getDefaultTab(router.query.tab as string, user) : null;
  const subtab = router ? router.query!.subtab : null;
  const isMobile = useIsMobile();
  const intl = useIntl();

  return (
    <Margin
      mb={30}
      css={{
        width: "100%",
      }}
    >
      {!user.searchable && (
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
      )}

      <Grid>
        <RowWrap>
          <Col width={[1, 1, 1, 4 / 12]}>
            <Margin mt={30}>
              <div
                data-intercom-target={user.isSelfie ? "User Details" : null}
                css={{
                  borderRadius: 5,
                  backgroundColor: palette.white,
                  border: `solid 1px ${palette.lightestGray}`,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Margin mt={16}>
                  <div
                    css={{
                      position: "relative",
                    }}
                  >
                    {user.isSelfie ? (
                      <Mutation<ISetProfilePictureMutation, ISetProfilePictureMutationVariables>
                        mutation={SetProfilePicture}
                      >
                        {(setPicture) => (
                          <ChangeableProfilePicture
                            image={user.avatar}
                            name={user.fullName!}
                            disruptor={(user.__typename === "VendorUser" && user.isDisruptor) || false}
                            innovator={(user.__typename === "GovernmentUser" && user.isInnovator) || false}
                            onChange={(picture) => {
                              setPicture({
                                variables: {
                                  userId: user._id,
                                  picture,
                                },
                                optimisticResponse: {
                                  __typename: "Mutation",
                                  setProfilePicture: {
                                    __typename: user.__typename as any,
                                    _id: user._id,
                                    avatar: picture,
                                  },
                                },
                              });
                            }}
                          />
                        )}
                      </Mutation>
                    ) : (
                      <ProfilePicture
                        image={user.avatar}
                        name={user.fullName}
                        size={100}
                        css={{
                          zIndex: 2,
                        }}
                        innovator={user.__typename === "GovernmentUser" && user.isInnovator}
                        disruptor={(user.__typename === "VendorUser" && user.isDisruptor) || false}
                      />
                    )}

                    {user.__typename === "GovernmentUser" && user.isInnovator && (
                      <div
                        css={{
                          position: "absolute",
                          right: 5,
                          bottom: 5,
                          zIndex: 3,
                        }}
                      >
                        <InnovatorsIcon height={20} width={20} />
                      </div>
                    )}
                  </div>
                </Margin>
                <Margin mt={18}>
                  <h1
                    css={{
                      fontSize: 24,
                      color: palette.text,
                      margin: 0,
                      textAlign: "center",
                      padding: "0 15px",
                      wordBreak: "break-word",
                    }}
                  >
                    {user.fullName}
                  </h1>
                </Margin>
                <Margin
                  mt={8}
                  css={{
                    display: "flex",
                  }}
                >
                  {user.__typename === "PendingUser" ? (
                    <span
                      css={{
                        fontSize: 16,
                        color: palette.darkGray,
                        margin: "0 auto",
                        fontWeight: "normal",
                        textAlign: "center",
                        padding: "0 16px",
                        display: "flex",
                        wordBreak: "break-word",
                      }}
                    >
                      {user.jobTitle}
                    </span>
                  ) : (
                    <span
                      css={{
                        fontSize: 16,
                        color: palette.mediumGray,
                        margin: "0 auto",
                        wordBreak: "break-word",
                        fontWeight: "normal",
                        textAlign: "center",
                        padding: "0 16px",
                      }}
                    >
                      {user.__typename === "VendorUser" && user.company && (
                        <FormattedMessage
                          defaultMessage="{jobTitle} at <company>{companyName}</company>"
                          id="PYjq3P"
                          values={{
                            jobTitle: user.jobTitle,
                            companyName: user.company.name,
                            company: (name: string) => {
                              if (user.__typename === "VendorUser" && user.company) {
                                return (
                                  <Link
                                    href="/vendors/[vendorSlug]"
                                    as={`/vendors/${user.company.slug}`}
                                    passHref={true}
                                  >
                                    <a
                                      css={{
                                        color: palette.mediumGray,
                                        ":hover": {
                                          textDecoration: "underline !important",
                                        },
                                      }}
                                    >
                                      {name}
                                    </a>
                                  </Link>
                                );
                              }

                              return null;
                            },
                          }}
                        />
                      )}{" "}
                      {user.__typename === "GovernmentUser" && user.government && (
                        <Link
                          href="/governments/[governmentSlug]/[[...content]]"
                          as={`/governments/${user.government.slug}`}
                          passHref={true}
                        >
                          <div
                            css={{
                              color: palette.mediumGray,
                              alignItems: "center",
                              ":hover": {
                                textDecoration: "underline !important",
                              },
                              cursor: "pointer",
                            }}
                          >
                            <span>{user.government.name}</span>

                            <Tooltip
                              maxWidth="230px"
                              textAlign="center"
                              aria-label={user.government.name}
                              label={user.government.city.countryName}
                              bg="blue.600"
                              py={2}
                            >
                              <Box as="span" display="inline-block" ml={2}>
                                <CountryFlag
                                  countryCode={user.government.city.country as TCountryCode}
                                  width={25}
                                  height={14}
                                />
                              </Box>
                            </Tooltip>
                          </div>
                        </Link>
                      )}
                    </span>
                  )}
                </Margin>

                <Margin mt={user.isSelfie ? 0 : 16} mb={16}>
                  <div
                    css={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <FollowButton user={user} />
                  </div>
                </Margin>

                {user.__typename === "GovernmentUser" && user.government && (
                  <>
                    <HorizontalLine color={palette.lightestGray} />
                    <div
                      css={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: 16,
                      }}
                    >
                      <span
                        css={{
                          textTransform: "uppercase",
                          fontSize: 12,
                          fontWeight: 500,
                          color: palette.darkGray,
                        }}
                      >
                        <FormattedMessage defaultMessage="Government" id="bh4rlK" />
                      </span>

                      <Margin
                        mt={8}
                        css={{
                          display: "inline-flex",
                        }}
                      >
                        <Link
                          href="/governments/[governmentSlug]/[[...content]]"
                          as={`/governments/${user.government.slug}`}
                          passHref={true}
                        >
                          <a
                            css={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <ProfilePicture image={user.government.logo} name={user.government.name} size={39} />

                            <Margin ml={8}>
                              <span
                                css={{
                                  color: palette.darkGray,
                                }}
                              >
                                {user.government.name}
                              </span>
                            </Margin>
                          </a>
                        </Link>
                      </Margin>
                    </div>
                  </>
                )}

                {user.__typename === "VendorUser" && user.company && (
                  <>
                    <HorizontalLine color={palette.lightestGray} />
                    <div
                      css={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: 16,
                      }}
                    >
                      <span
                        css={{
                          textTransform: "uppercase",
                          fontSize: 12,
                          fontWeight: 500,
                          color: palette.darkGray,
                        }}
                      >
                        <FormattedMessage defaultMessage="Company" id="9YazHG" />
                      </span>

                      <Margin
                        mt={8}
                        css={{
                          display: "inline-flex",
                        }}
                      >
                        <Link href="/vendors/[vendorSlug]" as={`/vendors/${user.company.slug}`} passHref={true}>
                          <a
                            css={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <ProfilePicture image={user.company.logo} name={user.company.name} size={39} />

                            <Margin ml={8}>
                              <span
                                css={{
                                  color: palette.darkGray,
                                }}
                              >
                                {user.company.name}
                              </span>
                            </Margin>
                          </a>
                        </Link>
                      </Margin>
                    </div>
                  </>
                )}

                {user.groups.length > 0 && (
                  <>
                    <HorizontalLine color={palette.lightestGray} />

                    <div
                      css={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: 16,
                      }}
                    >
                      <Margin mb={8}>
                        <span
                          css={{
                            textTransform: "uppercase",
                            fontSize: 12,
                            fontWeight: 500,
                            color: palette.darkGray,
                          }}
                        >
                          <FormattedMessage defaultMessage="Groups" id="hzmswI" />
                        </span>
                      </Margin>

                      <div
                        css={{
                          display: "grid",
                          gridRowGap: 12,
                        }}
                      >
                        {user.groups.map((group) => (
                          <div
                            key={group._id}
                            css={{
                              display: "inline-flex",
                            }}
                          >
                            <Link passHref={true} href="/groups/[groupSlug]" as={`/groups/${group.slug}`}>
                              <a
                                css={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                <ProfilePicture image={group.logo} name={group.name} size={39} />

                                <Margin ml={8}>
                                  <span
                                    css={{
                                      color: palette.darkGray,
                                    }}
                                  >
                                    {group.name}
                                  </span>
                                </Margin>
                              </a>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Margin>

            {isMobile && (
              <Margin mt={16}>
                <Navbar user={user} tab={tab} />
              </Margin>
            )}

            {user.__typename === "GovernmentUser" && (
              <Margin mt={20}>
                {user.isSelfie ? (
                  <ResourcesFormContextProvider>
                    <Mutation<ISetUserResourcesMutation, ISetUserResourcesMutationVariables>
                      mutation={SetUserResources}
                    >
                      {(setResources) => (
                        <ResourcesForm
                          resources={user.resources!}
                          dataIntercomTarget={user.isSelfie ? "User Resources" : null}
                          onAdd={(resource) => {
                            return setResources({
                              variables: {
                                resources: [resource, ...user.resources!].map((resource) => {
                                  return {
                                    type: resource.type || "",
                                    title: resource.title || "",
                                    url: (resource as ILinkResource).url || null,
                                    addedAt: resource.addedAt,
                                    filename: (resource as IDownloadResource).filename || null,
                                    body: (resource as ITextResource).body || null,
                                  };
                                }),
                              },
                            });
                          }}
                          onRemove={(removedResource) =>
                            setResources({
                              variables: {
                                resources: (user.resources as IFlatResource[])
                                  .filter((resource) => removedResource !== resource)
                                  .map((resource) => {
                                    return {
                                      type: resource.type || "",
                                      title: resource.title || "",
                                      url: (resource as ILinkResource).url || null,
                                      addedAt: resource.addedAt,
                                      filename: (resource as IDownloadResource).filename || null,
                                      body: (resource as ITextResource).body || null,
                                    };
                                  }),
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>
                  </ResourcesFormContextProvider>
                ) : (
                  <Resources titleColor={palette.darkGray} resources={user.resources as IFlatResource[]} />
                )}
              </Margin>
            )}

            {user.following.length > 0 && (
              <Margin mt={20}>
                <Following user={user as IUser} />
              </Margin>
            )}

            <Query<IRecentlyJoinedGovernmentsQuery, IRecentlyJoinedGovernmentsQueryVariables>
              query={RecentlyJoinedGovernments}
            >
              {({ data }) => {
                if (!data || !data.recentlyJoinedGovernments) {
                  return null;
                }

                return (
                  <Margin mt={20}>
                    <GovernmentsBox
                      title={intl.formatMessage({ defaultMessage: "Recently Joined", id: "17qYnl" })}
                      governments={data.recentlyJoinedGovernments}
                      data-intercom-target={user.isSelfie ? "Recently Joined Governments" : null}
                    />
                  </Margin>
                );
              }}
            </Query>

            {user.__typename === "GovernmentUser" &&
              user.government &&
              user.government.members &&
              user.government.members.length > 1 && (
                <Margin mt={20}>
                  <UsersBox
                    dataIntercomTarget={user.isSelfie ? "User From" : null}
                    title={intl.formatMessage(
                      { defaultMessage: "From {governmentName}", id: "r9xwVF" },
                      { governmentName: user.government.name },
                    )}
                    users={user.government.members.filter((member) => {
                      return member._id !== user._id;
                    })}
                  />
                </Margin>
              )}

            {user.__typename === "VendorUser" &&
              user.company &&
              user.company.members &&
              user.company.members.length > 1 && (
                <Margin mt={20}>
                  <UsersBox
                    dataIntercomTarget={user.isSelfie ? "User From" : null}
                    title={intl.formatMessage(
                      { defaultMessage: "From {companyName}", id: "w5263M" },
                      { companyName: user.company.name },
                    )}
                    users={user.company.members.filter((member) => {
                      return member._id !== user._id;
                    })}
                  />
                </Margin>
              )}
          </Col>

          <Col width={[1, 1, 1, 8 / 12]}>
            {!isMobile && (
              <Margin mt={30}>
                <Navbar user={user} tab={tab} />
              </Margin>
            )}

            {tab === "posts" && (
              <div>
                {user.__typename === "GovernmentUser" && user.government && user.isSelfie && (
                  <Margin mt={20}>
                    <WriteContentBox user={user} context="user" dataIntercomTarget="User Write Content Box" />
                  </Margin>
                )}

                <PostsTab user={user as IUser} />
              </div>
            )}
            {tab === "stories" && <StoriesTab user={user as IUser} />}
            {tab === "saved" && user.isSelfie && <SavedTab user={user as IUser} subtab={subtab as any} />}
            {tab === "projects" && <ProjectsTab user={user as IUser} />}

            {user.__typename === "GovernmentUser" && (
              <SharingSettingsModal
                user={user}
                isOpen={Boolean(router && router.query!.sharing && user.isSelfie)}
                onRequestClose={() => {
                  return router.push(`/[userSlug]/[[...tab]]`, `/@${user.slug}/settings`);
                }}
              />
            )}

            {user.isSelfie && tab === "settings" && (
              <div>
                {user.__typename === "GovernmentUser" && (
                  <Margin mt={20} mb={20}>
                    <SharingBox user={user} tab={tab} />
                  </Margin>
                )}

                <UserProfileSettings user={user as IUser} />
              </div>
            )}
          </Col>
        </RowWrap>
      </Grid>
    </Margin>
  );
}

function Navbar({ user, tab }: IUserProfileProps & { tab: string | null }) {
  const isMobile = useIsMobile();
  const intl = useIntl();

  return (
    <ProfileNavbar>
      {user.__typename === "GovernmentUser" && (
        <NewLinkTab
          href="/[userSlug]/[[...tab]]"
          as={`/@${user.slug}`}
          title={intl.formatMessage({ defaultMessage: "Activity", id: "ZmlNQ3" })}
          count={user.postsCount}
          active={tab === "posts"}
          dataIntercomTarget={user.isSelfie ? "User Activity Tab" : null}
        />
      )}

      {(user.storiesCount || 0) > 0 && (
        <NewLinkTab
          href="/[userSlug]/[[...tab]]?tab=stories"
          as={`/@${user.slug}/stories`}
          title={intl.formatMessage({ defaultMessage: "Stories", id: "Ldxym4" })}
          count={user.storiesCount}
          active={tab === "stories"}
          dataIntercomTarget={user.isSelfie ? "User Stories Tab" : null}
        />
      )}

      {user.__typename !== "PendingUser" && (
        <NewLinkTab
          href="/[userSlug]/[[...tab]]?tab=projects"
          as={`/@${user.slug}/projects`}
          title={intl.formatMessage({ defaultMessage: "Projects", id: "UxTJRa" })}
          count={user.projectsCount}
          active={tab === "projects"}
          dataIntercomTarget={user.isSelfie ? "User Projects Tab" : null}
        />
      )}

      {user.isSelfie && (
        <NewLinkTab
          href="/[userSlug]/[[...tab]]?tab=saved"
          as={`/@${user.slug}/saved`}
          title={intl.formatMessage({ defaultMessage: "Saved", id: "fsB/4p" })}
          count={countUserBookmarks(user)}
          active={tab === "saved"}
          dataIntercomTarget={user.isSelfie ? "User Saved Tab" : null}
        />
      )}

      {user.isSelfie && (
        <div
          css={
            isMobile
              ? {
                  marginTop: 8,
                }
              : {
                  marginLeft: "auto",
                  height: "100%",
                  padding: "0 20px",
                  borderLeft: `1px solid ${palette.lightestGray}`,
                  display: "flex",
                  alignItems: "center",
                }
          }
        >
          <NewLinkTab
            href="/[userSlug]/[[...tab]]?tab=settings"
            as={`/@${user.slug}/settings`}
            title={intl.formatMessage({ defaultMessage: "Settings", id: "D3idYv" })}
            active={tab === "settings"}
            dataIntercomTarget={user.isSelfie ? "User Settings Tab" : null}
          />
        </div>
      )}
    </ProfileNavbar>
  );
}
export default withRouter(UserProfile);
