import { AnchorButton, Button, Col, Loader, LoadingIndicator, RowWrap } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { IProjectCardFieldsFragment } from "@govlaunch/project";
import { ApolloConsumer, DataProxy, gql } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { DocumentNode } from "graphql";
import { get } from "lodash/fp";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FunctionComponent, ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Value } from "react-powerplug";
import Auth from "~/components/auth/Auth";
import Emoji from "~/components/Emoji";
import LoadingFeedCard from "~/components/feed/card/LoadingFeedCard";
import FeedItem from "~/components/feed/FeedItem";
import PostFeedItem from "~/components/feed/PostFeedItem";
import ProjectIcon from "~/components/icons/ProjectIcon";
import mediaQueries from "~/components/mediaQueries";
import { NoPosts, NoSaved } from "~/components/profile/EmptyBlocks";
import NoProjectsBox from "~/components/profile/NoProjectsBox";
import { Empty } from "~/components/profile/profile-components";
import Screen from "~/components/screen/Screen";
import { Margin } from "~/components/spacings";
import StoryListItem from "~/components/story/StoryListItem";
import StoryListItemActionsContext from "~/components/story/StoryListItemActionsContext";
import StoryListItemDividers from "~/components/story/StoryListItemDividers";
import StoryListItemHoverContainer from "~/components/story/StoryListItemHoverContainer";
import ToggleBookmark from "~/components/story/ToggleBookmark";
import ProductListItem from "~/components/user/ProductListItem";
import ConnectQuery from "~/lib/queries/ConnectQuery";
import { IBookmarkItemProductFragment } from "~/lib/queries/fragments/__generated__/BookmarkItemFields.generated";
import UserBookmarksQuery from "~/lib/queries/UserBookmarksQuery";
import UserProjectsQuery from "~/lib/queries/UserProjectsQuery";
import UserStoriesQuery from "~/lib/queries/UserStoriesQuery";
import { IConnectQuery, IConnectQueryVariables } from "~/lib/queries/__generated__/ConnectQuery.generated";
import {
  IUserBookmarksQuery,
  IUserBookmarksQueryVariables,
} from "~/lib/queries/__generated__/UserBookmarksQuery.generated";
import {
  IUserProjectsQuery,
  IUserProjectsQueryVariables,
} from "~/lib/queries/__generated__/UserProjectsQuery.generated";
import { IUserStoriesQuery, IUserStoriesQueryVariables } from "~/lib/queries/__generated__/UserStoriesQuery.generated";
import governmentStates, { IGovernmentStates } from "~/lib/utils/governmentStates";
import notUndefinedOrNull from "~/lib/utils/notUndefinedOrNull";
import CreateProjectModal from "~/src/components/project/CreateProjectModal";
import ProjectsListing from "~/src/components/project/listing/ProjectsListing";
import { IConnectTargetType, IGovernmentPost, IGovernmentUser, IGroupPost, IPendingUser, IUser } from "~/types/types";

interface INoProjectsProps {
  user: IUser;
}

export function PostsTab({ user }: { user: IUser }) {
  return (
    <Margin mt={20}>
      {!user.posts || (user.posts.length === 0 && user.isSelfie && <NoPosts />)}

      {user.posts && (
        <div
          css={mediaQueries({
            display: "grid",
            gridRowGap: 16,
          })}
        >
          {user.posts.map((post) => {
            return (
              <PostFeedItem
                key={post._id}
                createdAt={post.createdAt}
                post={{
                  _id: post._id,
                  body: post.body,
                  upvotesCount: post.upvotesCount,
                  commentsCount: post.commentsCount,
                  viewerDidBookmark: post.viewerDidBookmark,
                  viewerDidUpvote: post.viewerDidUpvote,
                  viewerCanEdit: post.viewerCanEdit,
                  viewerDidComment: post.viewerDidComment,
                  group: post.__typename === "GroupPost" ? (post as IGroupPost).group : null,
                  government: post.__typename === "GovernmentPost" ? (post as IGovernmentPost).government : null,
                  vendor: post.__typename === "VendorPost" ? post.vendor : null,
                  createdAt: post.createdAt,
                  author: post.author,
                  attachments: post.attachments,
                  links: post.links,
                  audience: post.audience,
                }}
              />
            );
          })}
        </div>
      )}

      {user.__typename === "GovernmentUser" && user.posts.length <= 4 && user.government && (
        <Query<IConnectQuery, IConnectQueryVariables>
          query={ConnectQuery}
          notifyOnNetworkStatusChange={true}
          variables={{
            targetId: user.government._id,
            targetType: IConnectTargetType.Government,
            limit: 15,
            beforeThisId: null,
            excludeUser: user._id,
          }}
        >
          {({ data, fetchMore, loading }) => {
            if (!data || !user.government) {
              return null;
            }

            return (
              <div>
                <Margin mt={26} mb={16}>
                  <h2
                    css={{
                      margin: 0,
                      color: palette.sealBlue,
                      fontWeight: "normal",
                      fontSize: 16,
                    }}
                  >
                    <FormattedMessage defaultMessage="From {name}" id="6ERahF" values={{ name: user.government.name }} />
                  </h2>
                </Margin>

                <div
                  css={{
                    display: "grid",
                    gridRowGap: 16,
                  }}
                >
                  {data &&
                    data.connect &&
                    data.connect.items &&
                    data.connect.items.map((feedItem) => {
                      return <FeedItem key={feedItem._id} feedItem={feedItem} />;
                    })}

                  {loading && <LoadingFeedCard />}

                  {!loading &&
                    data &&
                    data.connect.items.length > 0 &&
                    data.connect.items.length < data.connect.totalCount && (
                      <button
                        type="button"
                        css={{
                          justifySelf: "center",
                          color: palette.primary,
                          fontWeight: 600,
                          fontSize: 13,
                          letterSpacing: 1,
                          textTransform: "uppercase",
                          background: "none",
                          border: "none",
                          outline: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!data || !user.government) {
                            return null;
                          }

                          fetchMore({
                            query: ConnectQuery,
                            variables: {
                              targetId: user.government._id,
                              targetType: IConnectTargetType.Government,
                              beforeThisId: data.connect.items[data.connect.items.length - 1]._id,
                              limit: 15,
                              excludeUser: user._id,
                            },
                            updateQuery: (previousQueryResult: any, { fetchMoreResult }) => {
                              if (fetchMoreResult) {
                                return {
                                  ...previousQueryResult,
                                  connect: {
                                    ...fetchMoreResult.connect,
                                    items: [...previousQueryResult.connect.items, ...fetchMoreResult.connect.items],
                                  },
                                };
                              }

                              return previousQueryResult;
                            },
                          });
                        }}
                      >
                        <FormattedMessage defaultMessage="View more" id="34Up+l" /> ↓
                      </button>
                    )}
                </div>
              </div>
            );
          }}
        </Query>
      )}
    </Margin>
  );
}

export function StoriesTab({ user }: { user: IUser }) {
  return (
    <>
      {get("government.viewerIsMember", user) &&
        user &&
        user.__typename === "GovernmentUser" &&
        user.isInnovator &&
        user.isSelfie && (
          <Margin mt={20}>
            <WriteStory />
          </Margin>
        )}

      <Margin mt={20}>{user.storiesCount === 0 ? <NoPublishedStories user={user} /> : <Stories user={user} />}</Margin>
    </>
  );
}

export function ProjectsTab({ user }: { user: IUser }): ReactElement {
  const router = useRouter();
  return (
    <Margin mt={20}>
      <Query<IUserProjectsQuery, IUserProjectsQueryVariables>
        query={UserProjectsQuery}
        notifyOnNetworkStatusChange={true}
        variables={{
          slug: user.slug || "",
          cursor: null,
        }}
      >
        {({ data, loading, fetchMore }) => {
          if (!data || !data.user || loading) {
            return (
              <div
                css={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LoadingIndicator />
              </div>
            );
          }

          const userProjects = data.user.projects && data.user.projects.items ? data.user.projects.items : [];
          const governmentProjects =
            data.user.__typename === "GovernmentUser"
              ? clampProjects(data.user.projectsFromGovernment, userProjects.length)
              : [];
          const relatedProjects =
            data.user.__typename === "GovernmentUser"
              ? clampProjects(data.user.relatedProjects, userProjects.length + governmentProjects.length)
              : [];

          return (
            <div>
              {userProjects.length === 0 && <NoProjectsBlock user={user} />}

              {userProjects.length > 0 && (
                <div data-intercom-target={user.isSelfie ? "User Projects" : null}>
                  <ProjectsListing
                    withAddProjectSlot={(user.__typename !== "PendingUser" && user.isSelfie) || false}
                    projects={userProjects}
                    gridProps={{ columns: [1, 1, 2], spacingY: 6 }}
                    hasNextPage={data.user.projects?.pageInfo?.hasNextPage || false}
                    onModalClose={() => {
                      router.push("/[userSlug]/[[...tab]]", `/@${user.slug}/projects`);
                    }}
                    onLoadMore={() => {
                      fetchMore({
                        variables: {
                          slug: user.slug!,
                          cursor: data!.user!.projects!.pageInfo!.endCursor,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                          if (!fetchMoreResult) {
                            return previousResult;
                          }

                          return {
                            __typename: "Query",
                            user: {
                              ...fetchMoreResult.user!,
                              projects: {
                                __typename: "Projects",
                                ...fetchMoreResult.user!.projects,
                                items: previousResult.user!.projects!.items!.concat(
                                  fetchMoreResult.user!.projects!.items!,
                                ),
                                pageInfo: {
                                  ...fetchMoreResult.user!.projects!.pageInfo!,
                                },
                              },
                            },
                          };
                        },
                      });
                    }}
                  />
                </div>
              )}

              {data.user &&
                data.user.__typename === "GovernmentUser" &&
                data.user.government &&
                governmentProjects.length > 0 && (
                  <>
                    <Margin my={30}>
                      <h1
                        css={{
                          fontSize: 16,
                          color: palette.mediumGray,
                          fontWeight: 400,
                        }}
                      >
                        <FormattedMessage
                          defaultMessage="From <government>{governmentName}</government>" id="hbMGfD"
                          values={{
                            governmentName: data.user.government.name,
                            // eslint-disable-next-line
                            government: () => {
                              if (data.user && data.user.__typename === "GovernmentUser" && data.user.government) {
                                return (
                                  <Link
                                    href="/governments/[governmentSlug]/[[...content]]"
                                    as={`/governments/${data.user.government.slug}`}
                                    passHref={true}
                                  >
                                    <a
                                      css={{
                                        color: palette.darkGray,
                                        fontWeight: "bold",
                                        ":hover": {
                                          textDecoration: "underline !important",
                                        },
                                      }}
                                    >
                                      {data.user.government.name}
                                    </a>
                                  </Link>
                                );
                              }

                              return null;
                            },
                          }}
                        />
                      </h1>
                    </Margin>

                    <ProjectsListing
                      projects={governmentProjects}
                      onModalClose={() => {
                        router.push("/[userSlug]/[[...tab]]", `/@${user.slug}/projects`);
                      }}
                      gridProps={{ columns: [1, 1, 2], spacingY: 6 }}
                    />
                  </>
                )}

              {relatedProjects.length > 0 && data.user.__typename === "GovernmentUser" && data.user.government && (
                <>
                  <Margin my={30}>
                    <h1
                      css={{
                        color: palette.mediumGray,
                        fontWeight: 400,
                        fontSize: 16,
                      }}
                    >
                      <FormattedMessage defaultMessage="From other governments in" id="jl/ere" />{" "}
                      <span
                        css={{
                          color: palette.darkGray,
                          fontWeight: "bold",
                        }}
                      >
                        {governmentStates[data.user.government.state as keyof IGovernmentStates]}
                      </span>
                    </h1>
                  </Margin>

                  <ProjectsListing
                    projects={relatedProjects}
                    onModalClose={() => {
                      router.push("/[userSlug]/[[...tab]]", `/@${user.slug}/projects`);
                    }}
                    gridProps={{ columns: [1, 1, 2], spacingY: 6 }}
                  />
                </>
              )}
            </div>
          );
        }}
      </Query>
    </Margin>
  );
}

function getDefaultFilter(user: IUser, subtab?: string | null): string {
  if (subtab) {
    return subtab;
  }

  if ((user.savedPostsCount as number) > 0) {
    return "posts";
  }

  if ((user.savedProjectsCount as number) > 0) {
    return "projects";
  }

  if ((user.savedStoriesCount as number) > 0) {
    return "stories";
  }

  if ((user.savedProductsCount as number) > 0) {
    return "products";
  }

  return "projects";
}

export const SavedTab = ({ user, subtab }: { user: IUser; subtab?: string | null }) => {
  const [filter, setFilter] = React.useState(getDefaultFilter(user, subtab));
  const router = useRouter();

  return (
    <Query<IUserBookmarksQuery, IUserBookmarksQueryVariables>
      query={UserBookmarksQuery}
      variables={{
        slug: user.slug!,
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading }) => {
        if (loading) {
          return (
            <Margin mt={20} mb={20}>
              <div
                css={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  padding: "30px 0",
                  backgroundColor: palette.white,
                  border: `solid 1px ${palette.lightestSealBlue}`,
                  borderRadius: 4,
                }}
              >
                <Loader />
              </div>
            </Margin>
          );
        } else if (!data || !data.user) {
          return null;
        }

        const bookmarks = data.user!.bookmarks;

        if (!bookmarks) {
          return null;
        }

        const filtered = bookmarks.filter((bookmark) => {
          if (bookmark === null) {
            return false;
          }

          if (filter === "all") {
            return ["Story", "Product"].includes(bookmark.__typename);
          } else if (filter === "stories") {
            return bookmark.__typename === "Story";
          } else if (filter === "projects") {
            return ["GroupProject", "GovernmentProject", "VendorProject"].includes(bookmark.__typename);
          } else if (filter === "products") {
            return bookmark.__typename === "Product";
          } else if (filter === "posts") {
            return ["GroupPost", "GovernmentPost", "VendorPost"].includes(bookmark.__typename);
          } else {
            return false;
          }
        });

        return (
          <RowWrap>
            <Col width={[1, 1, 1, 1]}>
              <Margin my={20}>
                <div
                  css={{
                    flex: 1,
                    backgroundColor: palette.white,
                    border: `solid 1px ${palette.lightestSealBlue}`,
                    borderRadius: 4,
                  }}
                >
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 15,
                      overflow: "hidden",
                      borderBottom: `solid 1px ${palette.lightestGray}`,
                    }}
                  >
                    <div
                      css={{
                        display: "inline-flex",
                        "& > :not(:last-of-type)": {
                          marginRight: 5,
                        },
                      }}
                    >
                      <span
                        role="button"
                        onClick={() => setFilter("posts")}
                        css={{
                          padding: "5px 10px",
                          fontSize: 12,
                          backgroundColor: filter === "posts" ? palette.lightBlue : palette.lightestSealBlue,
                          borderRadius: 20,
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                      >
                        <FormattedMessage defaultMessage="Posts" id="eR3YIn" />
                      </span>

                      <span
                        role="button"
                        onClick={() => setFilter("projects")}
                        css={{
                          padding: "5px 10px",
                          fontSize: 12,
                          backgroundColor: filter === "projects" ? palette.lightBlue : palette.lightestSealBlue,
                          borderRadius: 20,
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                      >
                        <FormattedMessage defaultMessage="Projects" id="UxTJRa" />
                      </span>

                      <span
                        role="button"
                        onClick={() => setFilter("stories")}
                        css={{
                          padding: "5px 10px",
                          fontSize: 12,
                          backgroundColor: filter === "stories" ? palette.lightBlue : palette.lightestSealBlue,
                          borderRadius: 20,
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                      >
                        <FormattedMessage defaultMessage="Stories" id="Ldxym4" />
                      </span>

                      <span
                        role="button"
                        onClick={() => setFilter("products")}
                        css={{
                          padding: "5px 10px",
                          fontSize: 12,
                          backgroundColor: filter === "products" ? palette.lightBlue : palette.lightestSealBlue,
                          borderRadius: 20,
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                      >
                        <FormattedMessage defaultMessage="Products" id="7NFfmz" />
                      </span>
                    </div>
                  </div>

                  {filtered.length === 0 ? (
                    <NoSaved
                      css={{
                        border: 0,
                      }}
                    />
                  ) : (
                    <>
                      {filter === "posts" && (
                        <div
                          css={mediaQueries({
                            display: "grid",
                            gridRowGap: 24,
                            padding: 16,
                          })}
                        >
                          {filtered.map((bookmark: any) => {
                            if (
                              bookmark &&
                              (bookmark.__typename === "GroupPost" ||
                                bookmark.__typename === "GovernmentPost" ||
                                bookmark.__typename === "VendorPost")
                            ) {
                              return (
                                <PostFeedItem
                                  key={bookmark._id}
                                  createdAt={bookmark.createdAt}
                                  post={{
                                    _id: bookmark._id,
                                    body: bookmark.body,
                                    upvotesCount: bookmark.upvotesCount,
                                    commentsCount: bookmark.commentsCount,
                                    viewerDidBookmark: bookmark.viewerDidBookmark,
                                    viewerDidUpvote: bookmark.viewerDidUpvote,
                                    viewerCanEdit: bookmark.viewerCanEdit,
                                    viewerDidComment: bookmark.viewerDidComment,
                                    group: bookmark.__typename === "GroupPost" ? bookmark.postGroup : null,
                                    government:
                                      bookmark.__typename === "GovernmentPost" ? bookmark.postGovernment : null,
                                    vendor: bookmark.__typename === "VendorPost" ? bookmark.postVendor : null,
                                    createdAt: bookmark.createdAt,
                                    author: bookmark.postAuthor,
                                    attachments: bookmark.postAttachments,
                                    links: bookmark.postLinks,
                                    audience: bookmark.audience,
                                  }}
                                />
                              );
                            }

                            return null;
                          })}
                        </div>
                      )}

                      {filter === "projects" && (
                        <ProjectsListing
                          projects={filtered as IProjectCardFieldsFragment[]}
                          onModalClose={() => {
                            router.push("/[userSlug]/[[...tab]]", `/@${user.slug}/saved`);
                          }}
                          gridProps={{ columns: [1, 1, 2], spacingY: 6, margin: 4 }}
                        />
                      )}

                      {filter === "stories" && (
                        <StoryListItemDividers>
                          {filtered.filter(notUndefinedOrNull).map((bookmark) => (
                            <StoryListItemHoverContainer
                              key={bookmark!._id}
                              css={{
                                padding: 15,
                              }}
                            >
                              {bookmark.__typename === "Story" && (
                                <StoryListItem
                                  story={{
                                    ...bookmark,
                                    government: bookmark.storyGovernment,
                                  }}
                                />
                              )}
                            </StoryListItemHoverContainer>
                          ))}
                        </StoryListItemDividers>
                      )}

                      {filter === "products" &&
                        (filtered as IBookmarkItemProductFragment[]).map((bookmark) => (
                          <ToggleBookmark
                            key={bookmark!._id!}
                            viewerDidBookmark={bookmark!.viewerDidBookmark!}
                            targetId={bookmark!._id}
                            targetType="PRODUCT"
                          >
                            {(toggle) => (
                              <div
                                css={{
                                  borderTop: `solid 1px ${palette.lightestGray}`,
                                }}
                              >
                                {bookmark!.__typename === "Product" && (
                                  <ProductListItem product={bookmark} onBookmark={toggle} />
                                )}
                              </div>
                            )}
                          </ToggleBookmark>
                        ))}
                    </>
                  )}
                </div>
              </Margin>
            </Col>
          </RowWrap>
        );
      }}
    </Query>
  );
};

const WriteStory = (): ReactElement => {
  const intl = useIntl();

  return (
    <Value initial="">
      {({ value, set }) => (
        <div
          css={{
            padding: 15,
            backgroundColor: "#f0f5ff",
            border: `solid 1px ${palette.lightestGray}`,
            borderRadius: 5,
          }}
        >
          <span
            css={{
              display: "block",
              marginBottom: 10,
              color: palette.mediumGray,
              fontSize: 12,
              textTransform: "uppercase",
            }}
          >
            <FormattedMessage defaultMessage="Share your story" id="skGiBU" />
          </span>

          <span
            css={{
              fontSize: 16,
              fontWeight: 600,
              color: palette.darkGray,
            }}
          >
            <FormattedMessage defaultMessage="Pitch your story in one sentence" id="IBWque" />
          </span>

          <div
            css={{
              display: "flex",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <input
              onChange={(event) => set(event.target.value)}
              css={{
                flex: 1,
                height: 36,
                padding: 10,
                fontSize: 14,
                border: `solid 1px ${palette.lightSealBlue}`,
                borderRadius: 5,
                outline: 0,
                "&:focus": {
                  border: `solid 1px ${palette.primary}`,
                },
                placeholder: intl.formatMessage({ defaultMessage: 'Begin typing the title of your story...', id: 'q4Yt/6' }),
              }}
            />

            <Margin
              css={{
                display: "inline-flex",
              }}
              ml={10}
            >
              <Link href={value ? `/create-story?title=${encodeURIComponent(value)}` : `/create-story`} passHref={true}>
                <AnchorButton
                  theme="primary"
                  css={{
                    height: 36,
                    paddingRight: 30,
                    paddingLeft: 30,
                    color: palette.white,
                    fontWeight: 400,
                    fontSize: 12,
                    letterSpacing: 0.1,
                  }}
                >
                  <FormattedMessage defaultMessage="Start" id="mOFG3K" />
                </AnchorButton>
              </Link>
            </Margin>
          </div>

          <span
            css={{
              display: "block",
              marginTop: 10,
              color: palette.mediumGray,
              fontSize: 12,
            }}
          >
            <FormattedMessage defaultMessage="Please be descriptive and limit buzzwords. Don't worry, you'll be able to edit this 😉" id="2TZ6Nj" />
          </span>
        </div>
      )}
    </Value>
  );
};

const NoPublishedStories = ({ user }: { user: IUser }): ReactElement => {
  if (user.isSelfie) {
    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 60,
          backgroundColor: palette.white,
          border: `solid 1px ${palette.lightestGray}`,
          borderRadius: 5,
        }}
      >
        <Empty />

        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 20,
            color: palette.mediumGray,
            textAlign: "center",
          }}
        >
          <div
            css={{
              display: "grid",
              gridRowGap: 15,
            }}
          >
            <p
              css={{
                margin: 0,
                color: palette.darkGray,
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              <FormattedMessage defaultMessage="There are no published stories for you" id="aB3gpL" /> <Emoji>😞</Emoji>
            </p>

            <p
              css={{
                maxWidth: 380,
                margin: 0,
                fontWeight: 400,
                fontSize: 14,
              }}
            >
              <FormattedMessage defaultMessage="Stories allow you to quickly share how technology or creative practices are being used to improve your community." id="C49ST3" />
            </p>

            <Auth>
              {({ user }) => {
                if (user && user.__typename === "GovernmentUser" && user.isInnovator) {
                  return (
                    <Link href="/create-story" passHref={true}>
                      <AnchorButton
                        color={palette.innovatorBlue}
                        style={{
                          paddingRight: 50,
                          paddingLeft: 50,
                          fontWeight: 500,
                        }}
                      >
                        <FormattedMessage defaultMessage="Write my first Story" id="8qcpnw" />
                      </AnchorButton>
                    </Link>
                  );
                } else {
                  return null;
                }
              }}
            </Auth>

            <p
              css={{
                margin: 0,
                fontWeight: 400,
                fontSize: 14,
              }}
            >
              <FormattedMessage defaultMessage="Have something to share but don't have the time?" id="nmV1TC" />{" "}
              <a
                href="mailto:hello@govlaunch.com"
                css={{
                  color: palette.primary,
                }}
              >
                <FormattedMessage defaultMessage="Contact us" id="WnQ/AZ" />
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Screen>
        {({ isMobile }) => (
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 60,
              backgroundColor: palette.white,
              border: `solid 1px ${palette.lightestGray}`,
              borderRadius: 5,
            }}
          >
            <Empty height={isMobile ? 250 : 400} width={isMobile ? 300 : 600} />

            <div
              css={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: palette.mediumGray,
                textAlign: "center",
              }}
            >
              <Margin mt={15}>
                <p
                  css={{
                    margin: 0,
                    color: palette.darkGray,
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  <FormattedMessage
                    defaultMessage="There are no published stories for {firstName}" id="fV6OXh"
                    values={{ firstName: user.firstName }}
                  />
                </p>

                {user.isSelfie && (
                  <>
                    <p
                      css={{
                        margin: 0,
                        fontWeight: 400,
                        fontSize: 14,
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="Help {firstName} and collaborate on a story!" id="Bu6aVz"
                        values={{ firstName: user.firstName }}
                      />
                    </p>

                    <p
                      css={{
                        maxWidth: 370,
                        margin: 0,
                        fontWeight: 400,
                        fontSize: 14,
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="You can always <mailto>contact us</mailto> if you need any help collaborating on stories or creating content." id="/YaFZP"
                        values={{
                          // eslint-disable-next-line
                          mailto: (children: string) => {
                            return (
                              <a href="mailto:hello@govlaunch.com" css={{ color: palette.primary }}>
                                {children}
                              </a>
                            );
                          },
                        }}
                      />
                    </p>
                  </>
                )}
              </Margin>
            </div>
          </div>
        )}
      </Screen>
    );
  }
};

function writeSavedStoriesCount(cache: DataProxy, id: string, fragment: DocumentNode) {
  const cachedUser: IGovernmentUser | null = cache.readFragment({
    fragment: fragment,
    id,
  });

  if (!cachedUser) {
    return;
  }

  cache.writeFragment({
    fragment: fragment,
    id,
    data: {
      ...cachedUser,
      storiesCount: cachedUser.storiesCount! - 1,
    },
  });
}

function Stories({ user }: { user: IUser }): ReactElement {
  return (
    <Query<IUserStoriesQuery, IUserStoriesQueryVariables>
      notifyOnNetworkStatusChange={true}
      query={UserStoriesQuery}
      variables={{
        slug: user.slug!,
        cursor: null,
      }}
    >
      {({ data, loading, fetchMore, refetch }) => {
        if (!data || !data.user) {
          return null;
        }

        const { stories } = data.user;

        if (!stories) {
          return null;
        }

        return (
          <RowWrap>
            <Col width={1} order={[2, 2, 1, 1]}>
              <div
                css={{
                  backgroundColor: palette.white,
                  border: `solid 1px ${palette.lightestGray}`,
                  borderRadius: 5,
                }}
              >
                <div
                  css={{
                    padding: 15,
                    color: palette.sealBlue,
                    fontWeight: 500,
                    fontSize: 12,
                    letterSpacing: 0.5,
                    textTransform: "uppercase",
                    borderBottom: `solid 1px ${palette.lightestGray}`,
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Stories {count}" id="HKcOm0"
                    values={{
                      count: user.storiesCount || 0,
                    }}
                  />
                </div>

                <ApolloConsumer>
                  {({ cache }) => (
                    <StoryListItemActionsContext.Provider
                      value={{
                        onUnpublish: () => {},
                        onDelete: () => {
                          if ((user as IGovernmentUser).__typename === "GovernmentUser") {
                            writeSavedStoriesCount(
                              cache,
                              `GovernmentUser:${user._id}`,
                              gql`
                                fragment GovernmentUserFragment on GovernmentUser {
                                  _id
                                  storiesCount
                                }
                              `,
                            );
                          } else if ((user as IPendingUser).__typename === "PendingUser") {
                            writeSavedStoriesCount(
                              cache,
                              `PendingUser:${user._id}`,
                              gql`
                                fragment PendingUserFragment on PendingUser {
                                  _id
                                  storiesCount
                                }
                              `,
                            );
                          }

                          refetch();
                        },
                      }}
                    >
                      <StoryListItemDividers>
                        {(stories.items || []).map((story) => {
                          return (
                            <StoryListItemHoverContainer
                              key={story._id}
                              css={{
                                padding: 15,
                              }}
                            >
                              <StoryListItem story={story} />
                            </StoryListItemHoverContainer>
                          );
                        })}
                      </StoryListItemDividers>
                    </StoryListItemActionsContext.Provider>
                  )}
                </ApolloConsumer>

                {stories.pageInfo!.hasNextPage && (
                  <Button
                    onClick={() => {
                      fetchMore({
                        variables: {
                          slug: user.slug!,
                          cursor: stories!.pageInfo!.endCursor,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                          if (!fetchMoreResult) {
                            return previousResult;
                          }

                          return {
                            __typename: "Query",
                            user: {
                              ...fetchMoreResult.user!,
                              stories: {
                                __typename: "Stories",
                                ...fetchMoreResult.user!.stories,
                                items: previousResult.user!.stories!.items!.concat(
                                  fetchMoreResult.user!.stories!.items!,
                                ),
                                pageInfo: {
                                  ...fetchMoreResult.user!.stories!.pageInfo!,
                                },
                              },
                            },
                          };
                        },
                      });
                    }}
                    css={{
                      zIndex: 9999,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      margin: 1,
                      padding: 10,
                      color: palette.sealBlue,
                      fontWeight: 500,
                      fontSize: 12,
                      textTransform: "uppercase",
                      backgroundColor: palette.white,
                      border: "none",
                      // TODO: needs to combo with <PopularStory> hover
                      outline: "none",
                      cursor: loading ? "default" : "pointer",
                      "&:active": {
                        opacity: 0.7,
                      },
                      marginmt: 0,
                    }}
                    disabled={loading ? true : false}
                  >
                    {loading ? (
                      <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
                    ) : (
                      <FormattedMessage defaultMessage="Show more" id="aWpBzj" />
                    )}
                  </Button>
                )}
              </div>
            </Col>
          </RowWrap>
        );
      }}
    </Query>
  );
}

function clampProjects(projects: IProjectCardFieldsFragment[], showingProjects: number) {
  if (showingProjects <= 2) {
    return projects;
  }

  if (showingProjects <= 6) {
    return projects.slice(0, 2);
  }

  return [];
}

const NoProjectsBlock: FunctionComponent<INoProjectsProps> = ({ user }): ReactElement => {
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

  if (!user.isSelfie) {
    return (
      <div
        css={{
          background: "#fff",
          border: `1px solid ${palette.lightestGray}`,
          padding: 24,
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            maxWidth: 400,
            margin: "0 auto",
          }}
        >
          <ProjectIcon width={80} height={80} />

          <p
            css={{
              margin: "12px 0 0 0",
              color: palette.text,
              fontWeight: 500,
              fontSize: 16,
            }}
          >
            <FormattedMessage defaultMessage="{firstName} hasn't created any project yet" id="8hsuOz" />
          </p>
        </div>
      </div>
    );
  }

  return (
    <Margin mt={16}>
      <NoProjectsBox
        onAddProject={() => setShowCreateProjectModal(true)}
        dataIntercomTarget="User Projects"
        addButtonIntercomTarget="User Add Project"
      />

      <CreateProjectModal isOpen={showCreateProjectModal} onClose={() => setShowCreateProjectModal(false)} />
    </Margin>
  );
};
