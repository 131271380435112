export default function getSharingUrl(slug: string) {
  if (process.env.GOVLAUNCH_ENV === "production") {
    return `https://${slug}.users.govlaunch.com`;
  }

  if (process.env.GOVLAUNCH_ENV === "qa") {
    return `https://${slug}.users.qa.govlaunch.com`;
  }

  return `http://localhost:3005/?user=${slug}`;
}
