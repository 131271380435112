import { useMutation } from "@apollo/client";
import React, { useEffect, useRef } from "react";
import { Form, Section } from "~/components/user/settings";
import UpdateProfileEmailSubscription from "~/lib/mutations/UpdateProfileEmailSubscription";
import {
  IUpdateProfileEmailSubscriptionMutation,
  IUpdateProfileEmailSubscriptionMutationVariables,
} from "~/lib/mutations/__generated__/UpdateProfileEmailSubscription.generated";
import { IGovernmentUser, INewsletterFrequency, IPendingUser, IVendorUser } from "~/types/types";
import { Form as FinalForm } from "react-final-form";
import NewsletterFrequencyField from "~/components/user/settings/NewsletterFrequencyField";
import SubscribedToNewsletter from "~/components/user/settings/SubscribedToNewsletter";
import * as palette from "@govlaunch/palette";
import SubscribedToCampaign from "~/components/user/settings/SubscribedToCampaign";
import { Button, LoadingIndicator } from "@govlaunch/core";
import { Margin } from "~/components/spacings";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";

type TUserSubscriptionSettings =
  | Pick<IGovernmentUser, "_id" | "newsletterFrequency" | "subscribedToNewsletter" | "subscribedToCampaign">
  | Pick<IVendorUser, "_id" | "newsletterFrequency" | "subscribedToNewsletter" | "subscribedToCampaign">
  | Pick<IPendingUser, "_id" | "newsletterFrequency" | "subscribedToNewsletter" | "subscribedToCampaign">;

interface IUserEmailSubscriptionSettingsProps {
  user: TUserSubscriptionSettings;
}

export default function UserEmailSubscriptionSettings({ user }: IUserEmailSubscriptionSettingsProps) {
  const divRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const [updateProfileEmailSubscription] = useMutation<
    IUpdateProfileEmailSubscriptionMutation,
    IUpdateProfileEmailSubscriptionMutationVariables
  >(UpdateProfileEmailSubscription);

  useEffect(() => {
    if (
      router.query.from === "newsletter-unsubscribe" &&
      divRef.current &&
      typeof divRef.current.scrollIntoView === "function"
    ) {
      divRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [router.query.from]);

  return (
    <Section>
      <FinalForm
        keepDirtyOnReinitialize={true}
        onSubmit={async (data) => {
          await updateProfileEmailSubscription({
            variables: {
              user: {
                userId: user._id,
                newsletterFrequency: data.newsletterFrequency,
                subscribedToNewsletter: data.subscribedToNewsletter,
                subscribedToCampaign: data.subscribedToCampaign,
              },
            },
          });
        }}
        initialValues={{
          newsletterFrequency: user.newsletterFrequency || INewsletterFrequency.Biweekly,
          subscribedToNewsletter: user.subscribedToNewsletter || false,
          subscribedToCampaign: user.subscribedToCampaign,
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <Form onSubmit={handleSubmit}>
            <div
              ref={divRef}
              css={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                css={{
                  borderBottom: `1px solid ${palette.lightestGray}`,
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <h2
                  css={{
                    fontSize: 20,
                    margin: 0,
                    color: palette.darkGray,
                    alignSelf: "flex-start",
                  }}
                >
                  <FormattedMessage defaultMessage="Newsletter Preferences" id="SLtxEX" />
                </h2>
                <div
                  css={{
                    alignSelf: "flex-end",
                    color: palette.darkGray,
                    fontSize: 16,
                    margin: "0px 4px 4px 0px",
                  }}
                >
                  <label htmlFor="subscribeToNewsletter">
                    <FormattedMessage defaultMessage="Get Updates" id="fmob0k" />
                  </label>
                </div>
              </div>
              <div
                css={{
                  borderBottom: `1px solid ${palette.lightestGray}`,
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  paddingTop: 16,
                  paddingBottom: 16,
                }}
              >
                <NewsletterFrequencyField subscribed={values.subscribedToNewsletter} />
                <SubscribedToNewsletter css={{ alignSelf: "flex-end" }} />
              </div>
              <div
                css={{
                  borderBottom: `1px solid ${palette.lightestGray}`,
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  paddingTop: 16,
                  paddingBottom: 16,
                }}
              >
                <SubscribedToCampaign />
              </div>
              <Button
                disabled={submitting}
                type="submit"
                css={{ minWidth: 120, marginTop: 16, alignSelf: "flex-start" }}
              >
                {submitting ? (
                  <Margin ml={2}>
                    <LoadingIndicator />
                  </Margin>
                ) : (
                  <FormattedMessage defaultMessage="Update" id="BWpuKl" />
                )}
              </Button>
            </div>
          </Form>
        )}
      </FinalForm>
    </Section>
  );
}
