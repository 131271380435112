import { gql } from "@apollo/client";
import { ProjectCardFieldsFragment } from "@govlaunch/project";
import StoryFields from "~/lib/queries/fragments/StoryFields";

export default gql`
  fragment BookmarkStoryItem on Story {
    _id
    ...StoryFieldsLegacy
    storyStatus: status
    storyGovernment: government {
      _id
      name
      slug
      logo
      isInnovator
      state
      city {
        _id
        country
        countryName
      }
    }
  }

  fragment BookmarkItem on BookmarkTarget {
    ... on Collection {
      _id
      viewerDidBookmark
    }
    ... on Story {
      ...BookmarkStoryItem
    }
    ... on Product {
      _id
      name
      slug
      logo
      company {
        _id
        name
        slug
        logo
      }
      tags {
        _id
        slug
        name
      }
      viewerDidBookmark
      thumbnail
      isDisruptor
      description
      upvotesCount
      commentsCount
      governmentsUsingCount
    }

    ...ProjectCardFields

    ... on GroupPost {
      _id
      body
      audience
      viewerCanEdit
      upvotesCount
      commentsCount
      viewerDidBookmark
      viewerDidUpvote
      viewerDidComment
      createdAt
      postAuthor: author {
        ... on GovernmentUser {
          _id
          slug
          fullName
          avatar
          jobTitle
          email
          government {
            _id
            slug
            name
            logo
          }
        }
        ... on VendorUser {
          _id
          slug
          fullName
          avatar
          jobTitle
          email
          company {
            _id
            slug
            name
            logo
          }
        }
      }
      postGroup: group {
        _id
        slug
        name
        logo
      }
      postLinks: links {
        url
        description
        favicon
        title
        siteName
        addedAt
      }
      postAttachments: attachments {
        mimeType
        url
        filename
        sizeInBytes
      }
    }
    ... on GovernmentPost {
      _id
      body
      audience
      viewerCanEdit
      upvotesCount
      commentsCount
      viewerDidBookmark
      viewerDidUpvote
      viewerDidComment
      createdAt
      postGovernment: government {
        _id
        slug
        name
        logo
        city {
          _id
          country
          countryName
        }
      }
      postAuthor: author {
        __typename
        ... on GovernmentUser {
          _id
          slug
          fullName
          avatar
          jobTitle
          email
          government {
            _id
            slug
            name
            logo
          }
        }
        ... on VendorUser {
          _id
          slug
          fullName
          avatar
          jobTitle
          email
          company {
            _id
            slug
            name
            logo
          }
        }
      }
      postLinks: links {
        url
        description
        favicon
        title
        siteName
        addedAt
      }
      postAttachments: attachments {
        mimeType
        url
        filename
        sizeInBytes
      }
    }
    ... on VendorPost {
      _id
      body
      audience
      viewerCanEdit
      upvotesCount
      commentsCount
      viewerDidBookmark
      viewerDidUpvote
      viewerDidComment
      createdAt
      postVendor: vendor {
        _id
        slug
        name
        logo
      }
      postAuthor: author {
        __typename
        ... on GovernmentUser {
          _id
          slug
          fullName
          avatar
          jobTitle
          email
          government {
            _id
            slug
            name
            logo
          }
        }
        ... on VendorUser {
          _id
          slug
          fullName
          avatar
          jobTitle
          email
          company {
            _id
            slug
            name
            logo
          }
        }
      }
      postLinks: links {
        url
        description
        favicon
        title
        siteName
        addedAt
      }
      postAttachments: attachments {
        mimeType
        url
        filename
        sizeInBytes
      }
    }
  }

  ${StoryFields}
  ${ProjectCardFieldsFragment}
`;
