import React, { ReactNode, ReactElement } from "react";
import { detect } from "detect-browser";

export default function Emoji({ children, ...props }: { children: ReactNode }): ReactElement | null {
  const browser = detect();

  if (browser && browser.name === "ie") {
    // TODO: fallback to image
    return null;
  }

  return (
    <span
      {...props}
      style={{
        // https://www.tjvantoll.com/2016/06/15/emoji-on-windows
        fontFamily: "Segoe UI Emoji",
      }}
    >
      {children}
    </span>
  );
}
