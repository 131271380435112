import { gql } from "@apollo/client";

export default gql`
  mutation SetProfilePicture($userId: ObjectId!, $picture: String!) {
    setProfilePicture(userId: $userId, picture: $picture) {
      ... on GovernmentUser {
        _id
        avatar
      }
      ... on VendorUser {
        _id
        avatar
      }
      ... on PendingUser {
        _id
        avatar
      }
    }
  }
`;
