import { gql } from "@apollo/client";
import StoryFields from "~/lib/queries/fragments/StoryFields";

export default gql`
  query UserStories($slug: String!, $cursor: Cursor) {
    user(slug: $slug) {
      ... on GovernmentUser {
        _id
        stories(cursor: $cursor, limit: 8) {
          items {
            _id
            ...StoryFieldsLegacy
            storyStatus: status
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
      ... on PendingUser {
        _id
        stories(cursor: $cursor, limit: 8) {
          items {
            _id
            ...StoryFieldsLegacy
            storyStatus: status
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
      ... on VendorUser {
        _id
        stories(cursor: $cursor, limit: 8) {
          items {
            _id
            ...StoryFieldsLegacy
            storyStatus: status
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }

  ${StoryFields}
`;
