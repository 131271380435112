import * as palette from "@govlaunch/palette";
import { Phone } from "@govlaunch/core";
import React, { ReactElement, FunctionComponent } from "react";
import styled from "@emotion/styled";

export const Section = styled("div")({
  padding: 24,
  position: "relative",
  background: "#fff",
  borderRadius: 4,
  border: `1px solid ${palette.lightestGray}`,
});

export const Title = styled("h2")({
  paddingBottom: 20,
  fontSize: 20,
  margin: 0,
  color: palette.darkGray,
  borderBottom: `1px solid ${palette.lightestGray}`,
});

export const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export function FormGroup(props: any) {
  return (
    <Phone>
      {(isPhone: boolean) => (
        <div
          css={{
            display: "flex",
            width: "100%",
            marginTop: 25,
            position: "relative",
            alignItems: "center",
            flexWrap: isPhone ? "wrap" : null,
          }}
          {...props}
        />
      )}
    </Phone>
  );
}

export const Label = styled("label")({
  width: 170,
  textAlign: "left",
  fontWeight: 500,
  fontSize: 16,
  color: palette.sealBlue,
});

const Error = styled("span")({
  position: "absolute",
  bottom: 8,
  marginBottom: 3,
  left: 150,
  fontSize: 12,
  color: palette.red,
});

interface IInputProps {
  hasError?: boolean;
}

const Input = styled("input")<IInputProps>(
  {
    width: "100%",
    border: 0,
    background: "none",
    paddingBottom: 16,
    paddingLeft: 0,
    borderBottom: `1px solid ${palette.lightestGray}`,
    "&:focus, &:active": {
      borderBottom: `1px solid ${palette.lightGray}`,
      outline: 0,
    },
    color: palette.text,
    fontSize: 14,
    "&::placeholder": {
      color: palette.sealBlue,
      fontWeight: 500,
    },
  },
  ({ hasError }) => {
    if (hasError) {
      return {
        borderBottomColor: palette.red,
        ":focus, :active": {
          borderBottomColor: palette.red,
        },
      };
    }
  },
);

const Checkbox = styled("input")({
  margin: "0 !important",
});

interface ITextFieldProps {
  input: any;
  meta: any;
}

export function TextField({ input, meta, ...props }: ITextFieldProps): ReactElement {
  const hasError = meta.touched && (!!meta.error || !!meta.submitError);

  return (
    <>
      <Input {...props} {...input} hasError={hasError} />
      {hasError && <Error>{meta.error || meta.submitError}</Error>}
    </>
  );
}

interface ICheckboxFieldProps {
  id?: string;
  label?: string;
  input: any;
  meta: any;
}

export const CheckboxField: FunctionComponent<ICheckboxFieldProps> = ({
  input,
  meta,
  label,
  id,
  ...props
}): ReactElement => {
  const hasError = meta.touched && (!!meta.error || !!meta.submitError);

  return (
    <label
      htmlFor={id}
      css={{
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Checkbox id={id} type="checkbox" {...props} {...input} />

      {label && (
        <div
          css={{
            margin: 0,
            marginLeft: 10,
            fontWeight: "normal",
            fontSize: 14,
            marginTop: 1,
          }}
        >
          {label}
        </div>
      )}

      {hasError && <Error>{meta.error || meta.submitError}</Error>}
    </label>
  );
};

interface IRadioboxFieldProps {
  input: any;
  meta: any;
  disabled?: boolean;
  children?: any;
}

export function RadioboxField({ input, meta, disabled = false, children }: IRadioboxFieldProps) {
  const hasError = meta.touched && (!!meta.error || !!meta.submitError);

  return (
    <label
      css={{
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Checkbox type="checkbox" {...input} disabled={disabled} />

      <div
        css={{
          margin: 0,
          marginLeft: 10,
          fontWeight: "normal",
          fontSize: 14,
          marginTop: 1,
        }}
      >
        {children}
      </div>

      {hasError && <Error>{meta.error || meta.submitError}</Error>}
    </label>
  );
}
