import React from "react";
import { RadioboxField } from "~/components/user/settings/index";
import { Field } from "react-final-form";
import { INewsletterFrequency } from "~/types/types";
import * as palette from "@govlaunch/palette";
import { FormattedMessage } from "react-intl";

interface INewsletterFrequencyProps {
  subscribed: boolean | null;
}

export default function NewsletterFrequencyField({ subscribed }: INewsletterFrequencyProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <div
        css={{
          fontSize: 20,
        }}
      >
        <FormattedMessage defaultMessage="Personal Roundup" id="esGDoD" />
      </div>
      <div
        css={{
          color: palette.darkGray,
          fontSize: 16,
          marginBottom: 20,
        }}
      >
        <FormattedMessage defaultMessage="A personalized email that includes recommended content based on your interests and the Collections you follow." id="5rbvj2" />
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          css={{
            color: palette.darkGray,
          }}
        >
          <FormattedMessage defaultMessage="Frequency" id="vAW30j" />
        </div>
        <div css={{ marginLeft: 8 }}>
          <Field
            name="newsletterFrequency"
            type="radio"
            value={INewsletterFrequency.Weekly}
            component={RadioboxField}
            disabled={!subscribed}
          >
            <FormattedMessage defaultMessage="Weekly" id="/clOBU" />
          </Field>
        </div>
        <div css={{ marginLeft: 8 }}>
          <Field
            name="newsletterFrequency"
            type="radio"
            value={INewsletterFrequency.Biweekly}
            component={RadioboxField}
            disabled={!subscribed}
          >
            <FormattedMessage defaultMessage="Biweekly" id="GZ66QA" />
          </Field>
        </div>
        <div css={{ marginLeft: 8 }}>
          <Field
            name="newsletterFrequency"
            type="radio"
            value={INewsletterFrequency.Monthly}
            component={RadioboxField}
            disabled={!subscribed}
          >
            <FormattedMessage defaultMessage="Monthly" id="wYsv4Z" />
          </Field>
        </div>
      </div>
    </div>
  );
}
