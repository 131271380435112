import { gql } from "@apollo/client";

export default gql`
  fragment StoryFieldsLegacy on Story {
    _id
    slug
    title
    introduction
    viewerCanRead
    viewerDidBookmark
    viewerIsAuthor
    viewerIsCollaborator
    commentsCount
    upvotesCount
    medias {
      type
      url
    }
    verticalCover
    updatedAt
    modifiedAt
    createdAt
    lastPublishedAt
    collaborators {
      ... on GovernmentUser {
        _id
        slug
        firstName
        lastName
        fullName
        email
        jobTitle
        avatar
        isInnovator
        government {
          _id
          name
          city {
            _id
            name
            state
          }
        }
      }
      ... on PendingUser {
        _id
        slug
        firstName
        lastName
        fullName
        email
        jobTitle
        avatar
      }
    }
    author {
      ... on GovernmentUser {
        _id
        isSelfie
      }
      ... on VendorUser {
        _id
        isSelfie
      }
    }
    tags {
      _id
      name
    }
  }
`;
