import * as palette from "@govlaunch/palette";
import { Filestack } from "@govlaunch/core";
import { Hover } from "react-powerplug";
import ProfilePicture from "@govlaunch/profile-picture";
import React, { ReactElement, SyntheticEvent } from "react";
import { FormattedMessage } from "react-intl";

interface IChangeableProfilePictureProps {
  image?: string | null;
  name: string;
  onChange: (image: string) => void;
  innovator?: boolean;
  disruptor?: boolean;
}

export default function ChangeableProfilePicture({
  image = null,
  name,
  onChange,
  ...props
}: IChangeableProfilePictureProps): ReactElement {
  return (
    <Filestack
      options={{
        transformations: {
          rotate: false,
          circle: false,
          crop: true,
        },
      }}
      onSuccess={(result: any) => {
        if (result.filesUploaded.length > 0) {
          onChange(result.filesUploaded[0].url);
        }
      }}
      customRender={({ onPick }: { onPick: (e: SyntheticEvent) => void }) => (
        <Hover>
          {({ hovered, bind }) => (
            <div
              {...bind}
              css={{
                position: "relative",
                display: "flex",
                zIndex: 2,
              }}
            >
              <ProfilePicture image={image} name={name} size={100} {...props} />

              {hovered && (
                <div
                  css={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "100%",
                    background: "rgba(0, 0, 0, 0.6)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <button
                    onClick={onPick}
                    type="button"
                    css={{
                      color: "#fff",
                      border: 0,
                      textTransform: "uppercase",
                      letterSpacing: 0.5,
                      fontSize: 14,
                      fontWeight: 500,
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      outline: 0,
                    }}
                  >
                    <FormattedMessage defaultMessage="Change" id="BY343C" />
                  </button>

                  {image && (
                    <button
                      type="button"
                      onClick={() => onChange("")}
                      css={{
                        color: palette.orange,
                        border: 0,
                        textTransform: "uppercase",
                        letterSpacing: 0.5,
                        fontSize: 14,
                        fontWeight: 500,
                        marginTop: 10,
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        outline: 0,
                      }}
                    >
                      <FormattedMessage defaultMessage="Remove" id="G/yZLu" />
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </Hover>
      )}
    />
  );
}
