import { Mutation } from "@apollo/client/react/components";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import Link from "next/link";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import LeaveGroup from "~/lib/mutations/LeaveGroup";
import SetPrimaryGroup from "~/lib/mutations/SetPrimaryGroup";
import {
  ILeaveGroupMutation,
  ILeaveGroupMutationVariables,
} from "~/lib/mutations/__generated__/LeaveGroup.generated";
import {
  ISetPrimaryGroupMutation,
  ISetPrimaryGroupMutationVariables,
} from "~/lib/mutations/__generated__/SetPrimaryGroup.generated";
import { IGroup } from "~/types/types";
import { Margin } from "~/components/spacings";
import { Section, Title } from "~/components/user/settings/index";

interface IMyGroupsSection {
  groups: Pick<IGroup, "_id" | "slug" | "name" | "logo">[];
  primaryGroup: Pick<IGroup, "_id"> | null;
}

const MyGroupsSection: React.FunctionComponent<IMyGroupsSection> = ({ primaryGroup, groups }) => {
  const intl = useIntl();

  return (
    <Section data-intercom-target="User Groups">
      <Title>
        <FormattedMessage defaultMessage="My Groups" id="kkghcT" />
      </Title>

      <Mutation<ILeaveGroupMutation, ILeaveGroupMutationVariables> mutation={LeaveGroup}>
        {(leaveGroup) => (
          <Mutation<ISetPrimaryGroupMutation, ISetPrimaryGroupMutationVariables> mutation={SetPrimaryGroup}>
            {(setPrimaryGroup) => (
              <Margin mt={25}>
                <div
                  css={{
                    display: "grid",
                    gridRowGap: 12,
                  }}
                >
                  {groups.map((group) => (
                    <div
                      key={group._id}
                      css={{
                        display: "flex",
                      }}
                    >
                      <Link passHref={true} href="/groups/[groupSlug]" as={`/groups/${group.slug}`}>
                        <a>
                          <ProfilePicture size={46} name={group.name} image={group.logo} />
                        </a>
                      </Link>

                      <div
                        css={{
                          marginLeft: 12,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <Link passHref={true} href="/groups/[groupSlug]" as={`/groups/${group.slug}`}>
                          <a
                            css={{
                              color: palette.darkGray,
                              fontSize: 14,
                              fontWeight: 600,
                            }}
                          >
                            {group.name}
                          </a>
                        </Link>

                        <div
                          css={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {primaryGroup && primaryGroup._id === group._id && (
                            <span
                              css={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: palette.primary,
                                fontSize: 9,
                                borderRadius: 20,
                                backgroundColor: palette.lightestBlue,
                                height: 18,
                                padding: "0 14px",
                                fontWeight: 600,
                              }}
                            >
                              <FormattedMessage defaultMessage="Primary Group" id="avcQVt" />
                            </span>
                          )}

                          {(!primaryGroup || primaryGroup._id !== group._id) && (
                            <span
                              onClick={() => {
                                setPrimaryGroup({
                                  variables: {
                                    groupId: group._id,
                                  },
                                });
                              }}
                              css={{
                                fontSize: 12,
                                color: palette.primary,
                                cursor: "pointer",
                              }}
                            >
                              <FormattedMessage defaultMessage="Set as Primary" id="CYgweL" />
                            </span>
                          )}

                          <span
                            css={{
                              fontSize: 12,
                              color: palette.darkGray,
                              marginLeft: 4,
                              marginRight: 4,
                            }}
                          >
                            ·
                          </span>

                          <span
                            onClick={() => {
                              if (
                                window.confirm(
                                  intl.formatMessage(
                                    {
                                      defaultMessage: 'Are you sure you want to leave {name}?', id: 'fkoj3E',
                                    },
                                    {
                                      name: group.name,
                                    },
                                  ),
                                )
                              ) {
                                leaveGroup({
                                  variables: {
                                    groupId: group._id,
                                  },
                                });
                              }
                            }}
                            css={{
                              fontSize: 12,
                              color: palette.red,
                              cursor: "pointer",
                            }}
                          >
                            <FormattedMessage defaultMessage="Leave Group" id="aN2Gli" />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Margin>
            )}
          </Mutation>
        )}
      </Mutation>
    </Section>
  );
};

export default MyGroupsSection;
