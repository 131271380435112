import React from "react";
import { FormGroup, Label } from "~/components/user/settings/index";
import { Field } from "react-final-form";
import TagsSelectContainer from "~/components/tag/TagsSelect";
import FieldValidationError from "~/components/form/FieldValidationError";
import { ITagType } from "~/types/types";
import { FormattedMessage, useIntl } from "react-intl";
import * as palette from "@govlaunch/palette";
import { Phone } from "@govlaunch/core";

export default function DepartmentField() {
  const intl = useIntl();

  return (
    <Field name="department">
      {({ input, meta }) => (
        <FormGroup>
          <Phone>
            {(isPhone: boolean) => (
              <Label
                id="tags-input-memo-label"
                css={{
                  marginTop: 12,
                  marginBottom: "auto",
                  width: 140,
                  flexShrink: 0,
                  flexGrow: 0,
                  color: !input.value ? "#BE561E" : palette.darkGray,
                  paddingBottom: isPhone ? 16 : 0,
                }}
              >
                <FormattedMessage defaultMessage="Department" id="eXcD4S" />
              </Label>
            )}
          </Phone>
          <div
            css={{
              width: "100%",
              minWidth: 250,
            }}
          >
            <TagsSelectContainer
              onChange={(tags) => {
                if (tags.length > 0) {
                  input.onChange(tags[tags.length - 1]);
                } else {
                  input.onChange(null);
                }
              }}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              placeholder={
                input.value
                  ? " "
                  : intl.formatMessage({ defaultMessage: "What department do you work in?", id: "zAQz7C" })
              }
              value={input.value ? [input.value] : []}
              type={ITagType.Department}
            />
            <div>
              <FieldValidationError meta={meta} />
            </div>
          </div>
        </FormGroup>
      )}
    </Field>
  );
}
