import { AnchorButton, Button, Input } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FunctionComponent, ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import useClipboard from "~/lib/hooks/useClipboard";
import { IGovernmentUser } from "~/types/types";
import GearIcon from "~/components/icons/GearIcon";
import { NoSharingPage } from "~/components/profile/EmptyBlocks";
import getSharingUrl from "~/components/user/sharing/getSharingUrl";

interface ISharingProps {
  user: Pick<IGovernmentUser, "_id" | "slug" | "isSelfie" | "resources" | "enabledSharing">;
  tab: string | null;
}

const SharingBox: FunctionComponent<ISharingProps> = ({ user, tab }): ReactElement => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [copied, setClipboard] = useClipboard(inputRef);
  const router = useRouter();

  if (user.enabledSharing) {
    return (
      <div
        css={{
          backgroundColor: palette.white,
          borderRadius: 5,
          border: `solid 1px ${palette.lightestGray}`,
        }}
        data-intercom-target="User Sharing"
      >
        <div
          css={{
            padding: 15,
            textTransform: "uppercase",
            fontSize: 12,
            borderBottom: `1px solid ${palette.lightestGray}`,
            color: palette.darkGray,
            letterSpacing: 0.5,
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormattedMessage defaultMessage="Sharing" id="XfKNCg" />
          {user.enabledSharing && (
            <div
              role="button"
              css={{
                display: "inline-flex",
                marginLeft: "auto",
                cursor: "pointer",
              }}
            >
              <Link
                passHref={true}
                href={`/[userSlug]/[[...tab]]?tab=${tab}&sharing=1`}
                as={`/@${user.slug}/${tab}`}
                scroll={false}
              >
                <a>
                  <GearIcon width={20} height={20} />
                </a>
              </Link>
            </div>
          )}
        </div>
        <div
          css={{
            padding: 15,
          }}
        >
          <div
            css={{
              fontWeight: 500,
              fontSize: 14,
              color: palette.darkGray,
              marginBottom: 15,
            }}
          >
            <FormattedMessage defaultMessage="Your custom public page is now live!" id="1lfWb3" />
          </div>

          <div
            css={{
              fontSize: 14,
              color: palette.darkGray,
            }}
          >
            <div
              css={{
                position: "relative",
              }}
            >
              <div
                css={{
                  position: "absolute",
                  left: 15,
                  top: 14,
                  display: "inline-flex",
                }}
              >
                <svg width={15} height={15} viewBox="0 0 52.974 52.974" fill={palette.sealBlue}>
                  <path d="M49.467 3.51c-4.677-4.679-12.291-4.681-16.97 0l-9.192 9.192a.999.999 0 1 0 1.414 1.414l9.192-9.192a9.927 9.927 0 0 1 7.07-2.915c2.681 0 5.191 1.036 7.071 2.916s2.916 4.391 2.916 7.071c0 2.68-1.036 5.19-2.916 7.07L36.033 31.088c-3.898 3.898-10.244 3.898-14.143 0a.999.999 0 1 0-1.414 1.414c2.34 2.339 5.412 3.509 8.485 3.509s6.146-1.17 8.485-3.509L49.467 20.48a11.914 11.914 0 0 0 3.502-8.485c0-3.214-1.244-6.227-3.502-8.485z" />
                  <path d="M26.84 40.279l-7.778 7.778a9.928 9.928 0 0 1-7.071 2.916 9.93 9.93 0 0 1-7.071-2.916c-3.898-3.898-3.898-10.243 0-14.142l11.314-11.314c3.899-3.898 10.244-3.896 14.142 0a.999.999 0 1 0 1.414-1.414c-4.677-4.679-12.291-4.681-16.97 0L3.505 32.502a11.914 11.914 0 0 0-3.501 8.485c0 3.214 1.244 6.227 3.502 8.484s5.271 3.502 8.484 3.502c3.215 0 6.228-1.244 8.485-3.502l7.778-7.778a.999.999 0 1 0-1.413-1.414z" />
                </svg>
              </div>

              <Input
                innerRef={inputRef}
                readOnly={true}
                onChange={() => {}}
                value={getSharingUrl(user.slug as string)}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
                css={{
                  paddingLeft: 40,
                  borderColor: palette.lightSealBlue,
                  borderStyle: "dashed",
                }}
              />
            </div>
          </div>
        </div>
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: 16,
            borderTop: `1px solid ${palette.lightestGray}`,
          }}
        >
          <AnchorButton
            href={getSharingUrl(user.slug)}
            target="_blank"
            rel="noopener noreferrer"
            size="xsmall"
            color={palette.innovatorBlue}
            css={{
              display: "inline-flex",
              marginRight: 8,
            }}
          >
            <span
              css={{
                marginRight: 10,
                display: "inline-flex",
              }}
            >
              <svg width={15} height={15} viewBox="0 0 511.626 511.627" fill="#fff">
                <path d="M392.857 292.354h-18.274c-2.669 0-4.859.855-6.563 2.573-1.718 1.708-2.573 3.897-2.573 6.563v91.361c0 12.563-4.47 23.315-13.415 32.262-8.945 8.945-19.701 13.414-32.264 13.414H82.224c-12.562 0-23.317-4.469-32.264-13.414-8.945-8.946-13.417-19.698-13.417-32.262V155.31c0-12.562 4.471-23.313 13.417-32.259 8.947-8.947 19.702-13.418 32.264-13.418h200.994c2.669 0 4.859-.859 6.57-2.57 1.711-1.713 2.566-3.9 2.566-6.567V82.221c0-2.662-.855-4.853-2.566-6.563-1.711-1.713-3.901-2.568-6.57-2.568H82.224c-22.648 0-42.016 8.042-58.102 24.125C8.042 113.297 0 132.665 0 155.313v237.542c0 22.647 8.042 42.018 24.123 58.095 16.086 16.084 35.454 24.13 58.102 24.13h237.543c22.647 0 42.017-8.046 58.101-24.13 16.085-16.077 24.127-35.447 24.127-58.095v-91.358c0-2.669-.856-4.859-2.574-6.57-1.713-1.718-3.903-2.573-6.565-2.573z" />
                <path d="M506.199 41.971c-3.617-3.617-7.905-5.424-12.85-5.424H347.171c-4.948 0-9.233 1.807-12.847 5.424-3.617 3.615-5.428 7.898-5.428 12.847s1.811 9.233 5.428 12.85l50.247 50.248-186.147 186.151c-1.906 1.903-2.856 4.093-2.856 6.563 0 2.479.953 4.668 2.856 6.571l32.548 32.544c1.903 1.903 4.093 2.852 6.567 2.852s4.665-.948 6.567-2.852l186.148-186.148 50.251 50.248c3.614 3.617 7.898 5.426 12.847 5.426s9.233-1.809 12.851-5.426c3.617-3.616 5.424-7.898 5.424-12.847V54.818c-.001-4.952-1.814-9.232-5.428-12.847z" />
              </svg>
            </span>
            <span>
              <FormattedMessage defaultMessage="Open" id="JfG49w" />
            </span>
          </AnchorButton>

          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button theme="secondary" size="xsmall" onClick={setClipboard} color={palette.innovatorBlue}>
              <span
                css={{
                  marginRight: 10,
                  display: "inline-flex",
                }}
              >
                <svg width={15} height={15} viewBox="0 0 488.3 488.3" fill={palette.primary}>
                  <path d="M314.25 85.4h-227c-21.3 0-38.6 17.3-38.6 38.6v325.7c0 21.3 17.3 38.6 38.6 38.6h227c21.3 0 38.6-17.3 38.6-38.6V124c-.1-21.3-17.4-38.6-38.6-38.6zm11.5 364.2c0 6.4-5.2 11.6-11.6 11.6h-227c-6.4 0-11.6-5.2-11.6-11.6V124c0-6.4 5.2-11.6 11.6-11.6h227c6.4 0 11.6 5.2 11.6 11.6v325.6z" />
                  <path d="M401.05 0h-227c-21.3 0-38.6 17.3-38.6 38.6 0 7.5 6 13.5 13.5 13.5s13.5-6 13.5-13.5c0-6.4 5.2-11.6 11.6-11.6h227c6.4 0 11.6 5.2 11.6 11.6v325.7c0 6.4-5.2 11.6-11.6 11.6-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5c21.3 0 38.6-17.3 38.6-38.6V38.6c0-21.3-17.3-38.6-38.6-38.6z" />
                </svg>
              </span>

              <span>
                <FormattedMessage defaultMessage="Copy" id="4l6vz1" />
              </span>
            </Button>

            {copied && (
              <span
                css={{
                  marginLeft: 6,
                  color: palette.greenSuccess,
                }}
              >
                <FormattedMessage defaultMessage="Copied" id="p556q3" />!
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <NoSharingPage
      onStartSharing={() => {
        router.push(`/[userSlug]/[[...tab]]?tab=${tab}&sharing=1`, `/@${user.slug}/${tab}`);
      }}
    />
  );
};

export default SharingBox;
