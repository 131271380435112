import { Button, Col, Grid, RowWrap } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import Popover from "@govlaunch/popover";
import ProfilePicture from "@govlaunch/profile-picture";
import { random } from "lodash/fp";
import chunk from "lodash/fp/chunk";
import Link from "next/link";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Hover } from "react-powerplug";
import useIsMobile from "~/lib/hooks/useIsMobile";
import useIsTablet from "~/lib/hooks/useIsTablet";
import useShowMoreCounter from "~/lib/hooks/useShowMoreCounter";
import ToggleFollow from "~/lib/mutations/ToggleFollow";
import { IGovernmentUser, IUser } from "~/types/types";
import DelayedHover from "~/components/DelayedHover";
import { BlankIcon, EmptyIcon, QuestionsIcon } from "~/components/profile/icons-and-shapes";

const emptyCovers: ReactElement[] = [
  <EmptyIcon key={1} width={300} height={300} />,
  <BlankIcon key={2} width={300} height={300} />,
  <QuestionsIcon key={3} width={300} height={300} />,
];

interface IEmptyProps {
  width?: number;
  height?: number;
}

interface IFollowButtonProps {
  user: Pick<IUser, "__typename" | "_id" | "isSelfie" | "isFollowing">;
}

const EmptyMemo: React.FunctionComponent<IEmptyProps> = (props): ReactElement => {
  const index = random(0, emptyCovers.length - 1);
  const Icon = emptyCovers[index];

  return React.cloneElement(Icon, props);
};

export const Empty = React.memo(EmptyMemo);

interface IFollowingProps {
  user: IUser;
}

export function Following({ user }: IFollowingProps): ReactElement {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const { offset, hasMore, showMore } = useShowMoreCounter((user.following || []).length, isTablet ? 3 : 8);

  return (
    <div
      data-intercom-target={user.isSelfie ? "User Following" : null}
      css={{
        backgroundColor: palette.white,
        borderRadius: 5,
        border: `solid 1px ${palette.lightestGray}`,
      }}
    >
      <div
        css={{
          padding: 15,
          borderBottom: `solid 1px ${palette.lightestGray}`,
        }}
      >
        <h1
          css={{
            textTransform: "uppercase",
            fontSize: 12,
            color: palette.sealBlue,
            letterSpacing: 0.5,
            fontWeight: 500,
            margin: 0,
          }}
        >
          <FormattedMessage defaultMessage="Following ({count})" id="wnR8JW" values={{ count: user.following!.length }} />
        </h1>
      </div>

      {isMobile ? (
        <div
          css={{
            padding: 10,
          }}
        >
          <Grid gutter={15}>
            <RowWrap>
              {user.following!.slice(0, offset).map((follow) => {
                if (!follow) {
                  return null;
                }

                return (
                  <Col key={follow._id} mt={10} mb={10} width={[1, 6 / 12, 1, 1]}>
                    {(follow.__typename === "GovernmentUser" ||
                      follow.__typename === "PendingUser" ||
                      follow.__typename === "VendorUser") && (
                      <Link passHref={true} href="/[userSlug]/[[...tab]]" as={`/@${follow.slug}`}>
                        <a>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ProfilePicture
                              size={48}
                              image={follow.avatar}
                              name={follow.fullName}
                              innovator={(follow as IGovernmentUser).isInnovator}
                            />

                            <div
                              css={{
                                marginLeft: 15,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                css={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: palette.text,
                                }}
                              >
                                {follow.fullName}
                              </span>

                              <span
                                css={{
                                  fontSize: 14,
                                  color: palette.mediumGray,
                                }}
                              >
                                {follow.jobTitle}
                              </span>
                            </div>
                          </div>
                        </a>
                      </Link>
                    )}

                    {follow.__typename === "Government" && (
                      <Link
                        href="/governments/[governmentSlug]/[[...content]]"
                        as={`/governments/${follow.slug}`}
                        passHref={true}
                      >
                        <a>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ProfilePicture size={48} image={follow.logo} name={follow.name} />

                            <div
                              css={{
                                marginLeft: 15,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                css={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: palette.text,
                                }}
                              >
                                {follow.name}
                              </span>
                            </div>
                          </div>
                        </a>
                      </Link>
                    )}
                  </Col>
                );
              })}
            </RowWrap>
          </Grid>
        </div>
      ) : (
        <Grid gutter={15}>
          {chunk(4, user.following!.slice(0, offset)).map((row, index) => {
            return (
              <RowWrap
                key={index}
                mt={15}
                mb={15}
                css={{
                  justifyContent: row.length % 4 === 0 ? "center" : "flex-start",
                }}
              >
                {row.map((follow) => {
                  if (!follow) {
                    return null;
                  }

                  return (
                    <Col
                      key={follow._id}
                      width={3 / 12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {(follow.__typename === "GovernmentUser" ||
                        follow.__typename === "PendingUser" ||
                        follow.__typename === "VendorUser") && (
                        <Link passHref={true} href="/[userSlug]/[[...tab]]" as={`/@${follow.slug}`}>
                          <a
                            css={{
                              display: "inline-flex",
                            }}
                          >
                            <DelayedHover ms={300}>
                              {({ hover, ...bind }) => (
                                <Popover
                                  placement="top"
                                  isOpen={hover}
                                  backgroundColor={palette.innovatorBlue}
                                  borderColor={palette.innovatorBlue}
                                  boxStyle={{
                                    boxShadow: "0 10px 40px 0 rgba(34, 35, 40, 0.2)",
                                  }}
                                  render={() => (
                                    <div
                                      css={{
                                        padding: 10,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        color: palette.white,
                                        maxWidth: 200,
                                      }}
                                    >
                                      <span
                                        css={{
                                          fontSize: 14,
                                          fontWeight: 600,
                                        }}
                                      >
                                        {follow.fullName}
                                      </span>

                                      <span
                                        css={{
                                          fontSize: 12,
                                          fontWeight: 400,
                                          marginTop: 5,
                                        }}
                                      >
                                        {follow.jobTitle}
                                      </span>
                                    </div>
                                  )}
                                >
                                  {({ innerRef }: { innerRef: () => any }) => (
                                    <ProfilePicture
                                      {...bind}
                                      innerRef={innerRef}
                                      size={60}
                                      image={follow.avatar}
                                      name={follow.fullName}
                                      innovator={(follow as IGovernmentUser).isInnovator}
                                    />
                                  )}
                                </Popover>
                              )}
                            </DelayedHover>
                          </a>
                        </Link>
                      )}

                      {follow.__typename === "Government" && (
                        <Link
                          href="/governments/[governmentSlug]/[[...content]]"
                          as={`/governments/${follow.slug}`}
                          passHref={true}
                        >
                          <a
                            css={{
                              display: "inline-flex",
                            }}
                          >
                            <DelayedHover ms={300}>
                              {({ hover, ...bind }) => (
                                <Popover
                                  placement="top"
                                  isOpen={hover}
                                  backgroundColor={palette.innovatorBlue}
                                  borderColor={palette.innovatorBlue}
                                  boxStyle={{
                                    boxShadow: "0 10px 40px 0 rgba(34, 35, 40, 0.2)",
                                  }}
                                  render={() => (
                                    <div
                                      css={{
                                        padding: 10,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        color: palette.white,
                                      }}
                                    >
                                      <span
                                        css={{
                                          fontSize: 14,
                                          fontWeight: 600,
                                        }}
                                      >
                                        {follow.name}
                                      </span>
                                    </div>
                                  )}
                                >
                                  {({ innerRef }: { innerRef: any }) => (
                                    <ProfilePicture
                                      {...bind}
                                      innerRef={innerRef}
                                      size={60}
                                      image={follow.logo}
                                      name={follow.name}
                                    />
                                  )}
                                </Popover>
                              )}
                            </DelayedHover>
                          </a>
                        </Link>
                      )}
                    </Col>
                  );
                })}
              </RowWrap>
            );
          })}
        </Grid>
      )}

      {hasMore && <ShowMore onClick={showMore} />}
    </div>
  );
}

const ShowMore = (props: any) => (
  <div
    role="button"
    aria-label="Show more"
    css={{
      padding: 15,
      textTransform: "uppercase",
      color: palette.sealBlue,
      fontSize: 12,
      textAlign: "center",
      cursor: "pointer",
      borderTop: `solid 1px ${palette.lightestGray}`,
      "&:hover": {
        color: palette.darkGray,
      },
    }}
    {...props}
  >
    <FormattedMessage defaultMessage="Show more" id="aWpBzj" />
  </div>
);

export function FollowButton({ user }: IFollowButtonProps): ReactElement | null {
  if (user.isSelfie) {
    return null;
  }

  return (
    <Hover>
      {({ hovered, bind }) => (
        <ToggleFollow targetId={user._id} entityType={user.__typename} viewerIsFollowing={user.isFollowing!}>
          {({ toggle }) => {
            if (!user.isFollowing) {
              return (
                <Button
                  theme="primary"
                  color={palette.lightGray}
                  textColor={palette.darkGray}
                  onClick={toggle}
                  style={{
                    height: 38,
                    padding: "0 38px",
                    fontWeight: "bold",
                  }}
                  {...bind}
                >
                  <FormattedMessage defaultMessage="Follow" id="ieGrWo" />
                </Button>
              );
            }

            if (!hovered) {
              return (
                <Button
                  theme="primary"
                  color={palette.lightGray}
                  textColor={palette.darkGray}
                  onClick={toggle}
                  style={{
                    height: 38,
                    padding: "0 32px",
                    fontWeight: "bold",
                  }}
                  {...bind}
                >
                  <FormattedMessage defaultMessage="Following" id="cPIKU2" />
                </Button>
              );
            }

            return (
              <Button
                theme="primary"
                color={palette.red}
                textColor={palette.white}
                onClick={toggle}
                style={{
                  height: 38,
                  padding: "0 38px",
                  fontWeight: "bold",
                }}
                {...bind}
              >
                <FormattedMessage defaultMessage="Unfollow" id="izWS4J" />
              </Button>
            );
          }}
        </ToggleFollow>
      )}
    </Hover>
  );
}
