import { gql } from "@apollo/client";
import BookmarkItemFields from "~/lib/queries/fragments/BookmarkItemFields";

export default gql`
  query UserBookmarks($slug: String!) {
    user(slug: $slug) {
      ... on GovernmentUser {
        _id
        bookmarks {
          ...BookmarkItem
        }
      }
      ... on VendorUser {
        _id
        bookmarks {
          ...BookmarkItem
        }
      }
      ... on PendingUser {
        _id
        bookmarks {
          ...BookmarkItem
        }
      }
    }
  }

  ${BookmarkItemFields}
`;
