import React, { FunctionComponent } from "react";
import Responsive from "react-responsive";
import MobileDetectContext from "~/components/MobileDetectContext";
import { MediaQueryProps } from "~/components/screen/ReactResponsiveTypes";

interface IResponsiveSsrProps {
  isPhone: boolean;
  isTablet: boolean;
}

interface IScreenProps {
  children: (screen: IScreen) => any;
}

interface IScreen {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isPhone: boolean;
}

function getResponsiveSsrProps({ isPhone, isTablet }: IResponsiveSsrProps) {
  if (isPhone) {
    return {
      values: {
        width: 375,
        deviceWidth: 375,
      },
    };
  }

  if (isTablet) {
    return {
      values: {
        width: 720,
        deviceWidth: 720,
      },
    };
  }

  return {
    values: {
      width: 1024,
      deviceWidth: 1024,
    },
  };
}

const Phone: React.FunctionComponent<MediaQueryProps> = props => (
  <MobileDetectContext.Consumer>
    {flags => <Responsive {...props} {...getResponsiveSsrProps(flags)} maxWidth={720} />}
  </MobileDetectContext.Consumer>
);
const Tablet: React.FunctionComponent<MediaQueryProps> = props => (
  <MobileDetectContext.Consumer>
    {flags => <Responsive {...props} {...getResponsiveSsrProps(flags)} minWidth={720} maxWidth={960} />}
  </MobileDetectContext.Consumer>
);
const Mobile: React.FunctionComponent<MediaQueryProps> = props => (
  <MobileDetectContext.Consumer>
    {flags => <Responsive {...props} {...getResponsiveSsrProps(flags)} maxWidth={960} />}
  </MobileDetectContext.Consumer>
);
const Desktop: React.FunctionComponent<MediaQueryProps> = props => (
  <MobileDetectContext.Consumer>
    {flags => <Responsive {...props} {...getResponsiveSsrProps(flags)} minWidth={960} />}
  </MobileDetectContext.Consumer>
);

const Screen: FunctionComponent<IScreenProps> = ({ children }) => {
  return (
    <Desktop>
      {(isDesktop: any) => (
        <Mobile>
          {(isMobile: any) => (
            <Tablet>
              {(isTablet: any) => (
                <Phone>
                  {(isPhone: any) =>
                    children({
                      isDesktop,
                      isMobile,
                      isTablet,
                      isPhone,
                    })
                  }
                </Phone>
              )}
            </Tablet>
          )}
        </Mobile>
      )}
    </Desktop>
  );
};

export default Screen;

// eslint-disable-next-line
export { Phone, Tablet, Mobile, Desktop };
